import React, {Fragment} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import {clientWinMenu, set_tag_count} from './Menu';
import {Version} from './Version';

import * as moment from 'moment';
// import { htmlToText } from 'html-to-text';
var htmlToText = require('html-to-text');
window.h2t = htmlToText;


/*============== Misc Utilities ===============*/

window.g_moment = moment;	// debug
window.g_cookies = Cookies;


var _id = 10001;
function gen_id(prefix) {
	var nid = _id++;
	if (prefix === undefined)
		return nid;
	return prefix + nid;
}

function get_root_item(root, id) {
	const source = root._redir ? ldb.data[root._redir] : root;
	let item = source._items[ id ];
	if (item === undefined)
		item = {id};
	return item;
}

function deepcopy(obj) {
	// when shallow copy [...obj] won't do.
	return JSON.parse( JSON.stringify( obj ) );
}

function cap(buf) {
	if (typeof(buf) !== 'string')
		return buf;
	return buf.charAt(0).toUpperCase() + buf.slice(1).toLowerCase();
}

function em(count=1) {
	return <span style={{marginRight: count + 'em'}}></span>;
}

function clear_dict(dict) {
	return Object.keys(dict).length == 0;
}

function nofun() {}	// empty function... empty name is used up.

function go_url() {
	// eg. go_url('room', 5, 'shared', 32, 'create_task')
	// 	returns /room/5/shared/32/create_task
	//
	let loc = '/';
	// if (window.is_mobile)
		// loc += 'm/';

	loc += [...arguments].join('/');
	if (loc.slice(-1) == '/')	// remove trailing slash
		loc = loc.slice(0, -1)

	// log('lib', 'go_url', loc);
	return loc;
}

function go() {
	let prefix = '/#';
	// if (window.is_mobile)
		// prefix += '/m';

	const hash = '#' + go_url.apply(this, arguments);

	// const room = url2room(hash);
	// if (room)
		// window.g_setTitle(room.name);
	
	const url = '/' + hash;

	window.location = url;
	// log('lib', 'go', url);

	return url;
}

function go_replace() {
	let prefix = '/#';
	// if (window.is_mobile)
		// prefix += '/m';

	const loc = '/#' + go_url.apply(this, arguments);

	window.location.replace(loc);

	// log('lib', 'go_replace', loc);

	return loc;
}

function rel_url(rel) {
	let url = window.location.hash.slice(1); // remove leading #
	if (url.slice(-1) != '/' && rel.slice(0,1) != '/')
		url += '/';
	url += rel;
	return url;
}

window.g_go = go;

function abs_url(url) {
	let ret = '';
	const loc = window.location;
	if (loc.port == '3000')
		ret = loc.protocol + '//' + loc.hostname + ':8000';
	ret += url;
	return ret;
}

function settings_url(next_step='') {
	if (next_step)
		next_step = next_step + '/';
	
	let ret = '';
	
	const loc = window.location;
	
	ret = loc.protocol + '//' + ldb.data.base_url + '/onboard/transition/' + next_step + ldb.data.meta.nonce + '/';

	return ret;
}

function go_to_settings_url(next_step) {
	const url = settings_url(next_step);
	window.location.href = url;
}

function static_img_url(image_name) {
	const loc = window.location;
	
	let ret = loc.protocol + '//' + ldb.data.base_url + '/cwstatic/common/img/' + image_name;
	
	return ret;
}


function get_name_parts(name) {
	// Examples: 
	// 	John Smith : John
	// 	hello@company.com : hello
	// 	namecheap.com renewals : namecheap
	return name.split(/[\s@\.]/);
}

function get_first_name(name) {
	return get_name_parts(name)[0];
}

function get_name_alias(parts) {
	return parts.map((part) => part.charAt(0) ).join('');
}

function split_name(client) {
	client.first = '';
	client.last = '';
	if (!client.name)
		return;

	const parts = client.name.split(/\s+/);
	if (parts.length == 1) {
		client.first = client.name;
		return;
	}

	client.first = parts[0];
	client.last = parts.slice(-1)[0];

	// TBD. John A. Smith, John Avery Smith, John Van Der Hoven,
	//	John Smith Sr., John Smith III, 
	//	Dr. John Smith, 
}

function gen_room_name(orig_clients) {
	let name = '';
	let clients = orig_clients.filter(c => c.name.length);

	if (clients.length == 0) 
		return name;

	if (clients.length == 1) 
		return clients[0].name;

	const c0 = clients[0];
	const c1 = clients[1];

	log('mkroom', 'gen_room_name', clients, c0, c1);

	if (clients.length == 2 && c0.last == c1.last) {
		// Sally Smith, John Smith => Sally and John Smith
		name = c0.first + ' and ' + c1.first + ' ' + c1.last;
		return name;
	}

	// John Smith, Mary Smith, Ted Jones, Zach Spencer =>
	//	John Smith, Mary Smith, Ted Jones and Zach Spencer
	name = clients.slice(0,-1).map(c => c.name).join(', ') + 
			' and ' +  clients.slice(-1)[0].name;
	return name;
}

function ename(item) {	// email name. from_, to, cc, ..
	const mid=item.mid;
	const member = get_staff(mid);

	/*
	return <Link key={mid} title={member.name}
		style={{padding:'0 0.5em'}}
		to={'/member/' + mid}
		>{member.nickname}</Link>;
	*/
	return member.nickname;
}

// DEFUNCT...
function is_real_attachment(part) {
	// 99% of emails have is_attachment set. 
	// Special case: Sometimes, embedded images
	//	don't have that, but are attachments, eg
// content_type: "image/png"
// data: "iVBOR...."
// encoding: "base64"
// file_name: "image.png"
// is_attachment: false
	// So check for content_type image/...

	if (part.is_attachment)
		return true;
	
	if (part.content_type && part.content_type.slice(0,5) == 'image')
		return true;
	
	return false;
}

function is_inline_attachment(part) {
	// Treat inline application/pdf type of attachments as 
	//	external attachments so client can view/download.
	// Special case: Madhavi Kale.. emails with just plain part
	//	and PDF attachments.
	return part && ((part.content_type.indexOf('application') >= 0) || ((part.content_type.indexOf('image') >= 0) && (part.cid == '')));
}

function has_attachments(body) {
	let count = 0;
	if (!body)
		return count;

	body.map(part => {
		if (part.is_attachment || is_inline_attachment(part) ||
				part.content_type == 'text/calendar')
			count++;
	});
	return count;
}

function get_file_icon(filename) {
	// TBD Use the mimetype instead of the filename
	// Flesh out list and improve code. Just a placeholder for now

	if (!filename)
		return <i className={"pi pi-fw pi-paperclip"}></i>;
	
	filename = filename.trim().toLowerCase();

	let icon = '';

	if (filename.endsWith('.zip'))
		icon = '-archive';
	else if (filename.endsWith('.mp3'))
		icon = '-audio';
	else if (filename.endsWith('.xlsx') || filename.endsWith('.xls') || filename.endsWith('.csv'))
		icon = '-excel';
	else if (filename.endsWith('.png') || filename.endsWith('.jpg') || filename.endsWith('.jpeg'))
		icon = '-image';
	else if (filename.endsWith('.pdf'))
		icon = '-pdf';
	else if (filename.endsWith('.ppt'))
		icon = '-powerpoint';
	else if (filename.endsWith('.doc') || filename.endsWith('.docx'))
		icon = '-word';

	return <i className={"fa fa-fw fa-file" + icon + "-o"}></i>;
}

function ispin(working) {
	return working ? <i className="pi pi-fw pi-spin pi-spinner"></i> : null;
}

function display_readable_bytes(bytes, round_to=2, bold=false, html=true) {
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	
	let size_label = sizes[i];
	if (html)
		size_label = bold ? <b>{size_label}</b> : size_label;
	
	const num = (bytes / Math.pow(1024, i)).toFixed(round_to) * 1 + ' ';
	if (html)
		return <span>{num}{size_label}</span>;
	else
		return num + size_label;
}



// Remove staff and vendors from clusters.
//	if list is empry, remove cluster.
function clean_clusters() {
	const new_clusters = ldb.data.contacts.clusters.map(
		group => group.filter(pid => !pid2person(pid).kind)
		).filter(group => group.length)
	ldb.data.contacts.clusters = new_clusters;
	// log('lib', '+++clean_clusters', new_clusters);
	return new_clusters;
}

function set_cluster_search_buf() {
	const csbuflist = [];
	const clusters = clean_clusters();

	clusters.forEach(pidlist => {
		let csbuf = '';
		pidlist.forEach(pid => {
			const person = pid2person(pid);
			csbuf += person.name + '|' + 
				person.email + '|';
		});
		csbuflist.push(csbuf);
	});
	// log('lib', 'set_cluster_search_bufs', csbuflist);
	ldb.data.contacts.cluster_search_bufs = csbuflist;
}

function enames(list) {
	// sometimes list is null, eg. from_ for Sent Items.
	list = list || [];
	const items = list.map(ename).join(', ');
	// return items.length ? <span>{items}</span> : '';
	return items;
}

function validate_tel(tel) {
    var re = /[\d\-\.\+\(\)x]{10,20}/
    return re.test(tel);
}

function validate_email(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function clean_email(email) {
	return email.trim().toLowerCase();
}

function trimsplit(buf, sep) {
	buf = buf.trim();
	// if buf is '', don't return [''], instead return []
	if (!buf)
		return [];

	return buf.split(sep).map(part => part.trim() );
}

function pid2person(pid) {
	const person = ldb.data.contacts.persons._items[pid];
	if (person === undefined) 
		log('lib', 'pid2person: uknown pid', pid);
	return person
}

function email2person(email) {
	const root = ldb.data.contacts.persons;
	const lemail = email.toLowerCase();

	// Only store looked up persons in cache. Otherwise users with 10K+
	//	contacts will use up memory.
	let pid = root._e2p[lemail];
	if (pid)
		return root._items[pid];

	for (let i=0; i < root._idlist.length; i++) {
		pid = root._idlist[i];
		const person = root._items[pid];
		if (person.email == lemail) {
			root._e2p[lemail] = pid;
			log('lib', 'email2person', lemail, pid, person);
			return person;
		}
	}
	return {};	// Important. not null. 
}

function gen_email_summary(email) {
	// TBD Can from_pids be more than one?
	const from1 = pid2person(email.from_pids);
	const who = from1 ? from1.name + ' (' + from1.email + ')'  : '????';

	const summary = {from: who, subject: email.subject, 
			date: email.dt_sent};

	return JSON.stringify(summary);
}

function get_from_person_from_env(env) {
	const from = pid2person(env.from_pids);
	return from;
}

function get_other_rooms_from_rids_csv(person, ignore_rid) {
	if (!person)
		return [];
	
	if (person.kind != 'c')
		return [];
	
	let room_list = [];
	let parts = person.rids_csv.split(',');

	parts.forEach(part => {
		let tpart = part.trim();
		if (!tpart) {
			return;
		}
		
		let rid = parseInt(tpart, 10);
		
		if ((ignore_rid) && (rid == ignore_rid)) {
			return;
		}
		
		let room = get_room(rid);
		if (room) {
			room_list.push(room);
		}
	});
	
	return room_list;
}

function myatob(b64) {
	// Occasionally we get the 
	//	The string to be decoded contains characters outside of the
	//	Latin1 range.
	// with window.atob
	// To avoid that, follow this:
	
	return Base64.decode(b64);
}


// Walk through email MIME parts to find non-attachment parts:
// 	ctype == 'text/plain' : plain, 
// 	'text/html' : html body
function get_body_part(parts, ctype, attachment) {
	let text = '';
	attachment = attachment || false;	// default = false

	for (let i=0; i<parts.length; i++) {
		let part = parts[i];

		if (part.content_type != ctype)
			continue;

		if (attachment != part.is_attachment)
			continue;

		text = part.data;
		if (part.encoding == 'base64') 
			text = myatob(text);

		return text;
	}
	return text;
}

function get_body_parts_all(parts, ctype) {
	let all = [];
	let text;

	parts.forEach(part => {
		if (!part.is_attachment && part.content_type == ctype) {
			text = part.data;
			if (part.encoding == 'base64') {
				text = myatob(text);
			}
			all.push(text);
		}
	});
	return all;
}

function text2html(text) {
	return <div>
		{text.split('\n').map((item, key) => {
		  return <Fragment key={key}>{item}<br/></Fragment>
		})}
	</div>
}

function p2br(html) {
	// PrimeReact's Quill editor does not (yet)
	// 	recognize Shift ENTER for BR instread of P
	// <p> as line breaks looks terrible in sigature.
	// 	Replace <p>s with <br>

	return html.replace(/<p>/ig, '').replace(/<\/p>/ig, '<br/>');
}

function get_client_rid(member) {
	for (var i=0; i<member.rids.length; i++ ) {
		const rid = member.rids[i];
		const room = get_room( rid );
		if (!room.is_private)
			return rid;
	}
	return null;
}

function get_room_staff_by_name_fragment(room, fragment) {
	const staffs = room.staffs;
	
	const lfragment = fragment.toLowerCase();

	let found_staff = null;
	staffs._idlist.forEach(function(sid) {
		const staff = get_staff(sid);

		if (!staff.name)
			return;

		if (staff.name.toLowerCase().startsWith(lfragment))
			found_staff = staff
	});
	
	return found_staff;
}

function is_inbox(rid) {
	return get_room(rid).name == 'New Email';
}

function get_dest_rid(email) {
	if (!is_inbox(email.rid))
		return null;

	const mids = email.env._mids;

	for (var i=0; i<mids.length; i++) {
		const mid = mids[i];
		if (mid < 0)
			continue;
		const member = get_staff( mid );
		if (!member.is_staff)
			return get_client_rid(member);
	}
	return null;
}

function set_dest_rid(email) {
	// dest.rid is default client room, this email should move to.
	// if user overrides, it, it goes to pesonal email room.
	//	_move_to stores user's choice: dest_rid or null.
	const dr = email._move_to = email._dest_rid = get_dest_rid(email);
	log('lib', 'set_dest_rid', email.id, dr);
	if (dr) 
		log('lib', 'set_dest_rid', email.id, dr);
}

function get_mailbox_room() {
	if (!ldb.data.rooms)
		return null;
	
	let mailbox_room = null;
	
	ldb.data.rooms._idlist.forEach(rid => {
		const room = get_room(rid);
		if (room.is_mailbox)
			mailbox_room = room;
	});

	return mailbox_room;
}

function init_new_task(email) {
	const me = ldb.data.me;
	const now = moment();
	const id = -1;		// new task id is always -1

	let task = get_task(id);
	
	if (!task) 
		ldb.data.tasks._items[id] = task = {id};

	task.mid = me.id;
	task.rid = email.rid;
	task.eids = [email.id];
	task.note = '';
	task.name = email.env.subject;
	task.log = 'Created by ' + me.name + ' on ' + 
		now.format('MMMM Do YYYY, h:mm:ss a');

	task.dt_due = now.add(3, 'days').toDate();

	return task;
}

function task_due_date_delta_default() {
	const delta = ldb.data.org.settings.task_due_date_delta || 3;
	return parseInt(delta, 10);
}

function task_category_default() {
	const categories = ldb.data.org.settings.task_categories || [];
	return categories[0] || '';
}

function task_category_options() {
	const categories = ldb.data.org.settings.task_categories || [];
	if (categories.length == 0)
		return null;

	const options = categories.map( cat => ({label:cat, value:cat}) );
	options.unshift({label:'Choose:', value:''});
	return options;
}

function room_category_default() {
	const categories = ldb.data.org.settings.room_categories || [];
	return categories[0] || '';
}

function room_category_options() {
	const categories = ldb.data.org.settings.room_categories || [];
	if (categories.length == 0)
		return null;

	const options = categories.map( cat => ({label:cat, value:cat}) );
	options.unshift({label:'Choose:', value:''});
	return options;
}

function get_room_options(include_my_mailbox=false) {
	const room_ids = ldb.data.rooms._order || [];
	if (room_ids.length == 0)
		return null;

	const options = [];
	
	room_ids.forEach( room_id => {
		const room = get_room(room_id);
		if ((!include_my_mailbox) && room.is_mailbox)
			return;
		
		options.push({label:room.name, value:room.id});
	});
	options.unshift({label:'Choose:', value:''});
	return options;
}

function get_values_for_room_field(field_key) {
	const options = [];
	const values = [];
	if (field_key == '') {
		return options;
	}

	const room_ids = ldb.data.rooms._order || [];
	
	room_ids.forEach( room_id => {
		const room = get_room(room_id);
		
		let room_value = null;
		if (field_key == 'category') {
			room_value = {'value': room.category};
		} else {
			room_value = room.info[field_key];
		}

		if (room_value) {
			const room_entry = {label:room_value.value, value:room_value.value};
			if (!values.includes(room_value.value)) {
				options.push(room_entry);
				values.push(room_value.value);
			}
		}
	});
	
	return options;
}

function get_my_mailbox_room() {
	const room_ids = ldb.data.rooms._order || [];
	
	let my_mailbox = null;

	room_ids.forEach( room_id => {
		const room = get_room(room_id);
		if (room.is_mailbox)
			my_mailbox = room;
	});

	return my_mailbox;
}

function bulk_email_list_options() {
	const bulk_email_list_ids = ldb.data.bulk_email_lists._order || [];
	if (bulk_email_list_ids.length == 0)
		return null;
	
	const options = [];

	bulk_email_list_ids.forEach( b_id => {
		const b_list = ldb.data.bulk_email_lists._items[b_id];
		
		const rnum = b_list.data.rids ? b_list.data.rids.length : 0;
		const plural = (rnum == 1) ? '' : 's';
		
		const label = b_list.name + ' (' + rnum + ' room' + plural + ')';

		options.push({label:label, value:b_list.id});
	});
	options.unshift({label:'Choose:', value:''});
	return options;
}

function get_client_options(room) {
	const client_ids = room.clients._order || [];

	const options = [];
	
	client_ids.forEach( client_id => {
		const client = room.clients._items[client_id]; 
		options.push({label:client.name, value:client.id});
	});
	options.unshift({label:'Choose:', value:''});
	return options;
}

function get_signature_of_room(rid) {
	const alt_sigs = ldb.data.me.settings.alt_sigs;
	
	let signature = null;
	
	if (!alt_sigs)
		return signature;
	
	let num = 0;
	alt_sigs.forEach(function(sig) {
		if (sig.rooms.includes(rid)) {
			signature = sig;
			signature.num = num;
		}
		num++;
	});

	return signature;
}

function room_signature_options() {
	const alt_sigs = ldb.data.me.settings.alt_sigs;
	
	const def_sig = {label: 'Default', value: ''};
	
	if (!alt_sigs)
		return [def_sig];
	
	const options = alt_sigs.map( (sig, i) => ({label:sig.name, value:i}) );
	options.unshift(def_sig);
	
	return options;
}

function get_default_editor_style_settings() {
	let style_settings = '';
	
	const email_editor_font = ldb.data.me.settings.email_editor_font || 'Arial';
	const email_editor_font_size = ldb.data.me.settings.email_editor_font_size;
	const email_editor_line_format = ldb.data.me.settings.email_editor_line_format;
		
	if (email_editor_font) {
		style_settings += 'font-family: ' + email_editor_font + ';';
	}
	if (email_editor_font_size) {
		style_settings += 'font-size: ' + email_editor_font_size + 'px;';
	}
	if (email_editor_line_format) {
		style_settings += 'format: ' + email_editor_line_format + ';';
	}
	
	return style_settings;
}

function is_staff_a_recipient_of_shared_email(item, email) {
	let email_found = false;
	
	item.pinfo.to.forEach(function(i) {
		if (i.email == email)
			email_found = true;
	});
	item.pinfo.cc.forEach(function(i) {
		if (i.email == email)
			email_found = true;
	});
	item.pinfo.bcc.forEach(function(i) {
		if (i.email == email)
			email_found = true;
	});
	return email_found;
}

function icontains(buf, frag) {
	const ubuf = buf.toUpperCase();
	const ufrag = frag.toUpperCase();
	return ubuf.indexOf(ufrag) >= 0;
}

function list_has_ele(list, ele) {
	return list.indexOf(ele) >= 0;
}

function list_rm_ele(list, ele) {
	const index = list.indexOf(ele);
	if (index >= 0)
		list.splice(index, 1);
	// log('lib', 'list_rm_ele', index, ele, typeof(ele), list);
}

function list_toggle_ele(list, ele) {
	const index = list.indexOf(ele);
	if (index >= 0) {
		list.splice(index, 1);
		return false;
	}
	else {
		list.push(ele);
		return true;
	}
}

function list_rm_item(root, id) {
	list_rm_ele(root._idlist, id);
	list_rm_ele(root._order, id);
	if(id in root._items)
		delete root._items[id];
	log('lib', 'list_rm_item', root._kind, id);
}

function list_next_id(root, id, delta) {
	// default. Caller can also set it to -1 to move backwards
	if (delta === undefined)
		delta = 1;	

	const list = root._order;
	let index = list.indexOf(id);
	if (index >= 0) { 
		index += delta;
		// return next item in the list
		if (index < list.length)
			return list[index];
		// if end of list, try first one, unless that's what we found
		if (list.length != 1)
			return list[0];
	}
	return 0;	// id is never 0. so indicates error.
}

// ensure no duplicates
function list_add_item_nodup(list, item) {
	const index = list.indexOf(item);
	if (index < 0)
		list.push(item);
}

function rm_order(email) {
	const meta = ldb.data.folders[email.fid].meta;

	list_rm_ele(meta.order, email.id);
	list_rm_ele(meta.all_eids, email.id);
}

// defunct
function room_gen_mids(room) {
	const rid = room.id;

	room.mids = ldb.data.members._idlist.filter( id => 
		get_staff(id).rids.indexOf(rid) >= 0 );
}

// defunct
function room_keywords(room) {
	const members = ldb.data.members._items;
	room_gen_mids(room);
	const mnames = room.mids.map(
		(id) => ( members[id].name + ' ' + members[id].email ) 
			);
	room._keywords = room.name + ' ' + mnames.join(' ');
}

var html_tag = RegExp('<html>', 'ig');

function ebody(email) {
	var buf = email.body;

	if (email.is_deleted) {
		rm_order(email);
		return "Deleted";
	}

	if (!buf)
		return "Loading...";

	if (html_tag.test(buf)) {
		log('email', 'html2string');
		buf = htmlToText.fromString(buf);
	}

	return <pre style={{backgroundColor:'white', color:'black'}}>{buf}</pre>;

	/*
	return <div>{buf.split('\r\n').map((item, key) => {
		return <span key={key}>{item}<br/></span>
	})}</div>;
	*/
}

function html2plain(html) {
	let plain = '';

	try {
		plain = htmlToText.fromString(html);
	}
	catch(error) {
		log('lib', 'html2plain failed', html, error);
	}
	return plain;
}

// TBD: Use Intl.DateTimeFormat to get the localized date format properly
//	for India (d/m/y) and US (m/d/y), instead of us figuring it out
//	with timezone.


function set_mdy() {
	// called post_login, so mytimezone is set

	ldb.data.sys.is_mdy = ldb.data.me.mytimezone.substring(0,4) != 'Asia';
}

function edate(isodate) {
	const m = moment.utc(isodate);
	const buf = ldb.data.sys.is_mdy ?  'M/D/YY h:mm a': 'D/M/YY h:mm a';
	return m.local().format(buf);
}

function edate2(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  'MMM Do' : 'Do MMM';
	return m.format(buf);
}

function edate3(isodate) {
	if (!isodate)
		return '';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  'M/D/YY h:mm a': 'D/M/YY h:mm a';
	return m.format(buf);
}

function edate4(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  'MMM Do YYYY' : 'Do MMM YYYY'; 
	return m.format(buf);
}

function edate5(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = 'HH:mm:ss:sss'; 
	return m.format(buf);
}

function edate6(isodate) {
	if (!isodate)
		return '';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  'M/D/YY': 'D/M/YY';
	return m.format(buf);
}

function time_diff(start, end, to_fixed) {
	const start_date = new Date(start);
	const end_date = new Date(end);
	
	const start_sec = start_date.getTime() / 1000;
	const end_sec = end_date.getTime() / 1000;
	
	let diff = end_sec - start_sec;

	if (to_fixed) {
		diff = diff.toFixed(to_fixed);	
	}
	console.log('TIME DIFF', start_sec, end_sec, diff);
	
	return diff;
}

function fulldate(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  'MMMM Do YYYY' : 'Do MMMM YYYY';
	return m.format(buf);
}

function fulldatetime(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  
			'MMMM Do YYYY h:mm a': 'Do MMMM YYYY h:mm a';
	return m.format(buf);
}

function fulldatetimehuman(isodate) {
	if (!isodate)
		return '-';
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ?  
			'MMMM Do YYYY h:mm a (': 'Do MMMM YYYY h:mm a (';
	return m.format(buf) + 
		moment.duration(moment().diff(m)).humanize() + 
		' ago)';
}

function durhuman(isodate) {
	const m = moment(isodate);
	return <span className="humanized-duration">
		({moment.duration(moment().diff(m)).humanize()} ago)
	</span>
}

function durhuman_with_title(isodate) {
	const m = moment(isodate);
	
	let display_time = moment.duration(moment().diff(m)).humanize() + ' ago';
	
	display_time = display_time[0].toUpperCase() + display_time.slice(1);
	const buf = ldb.data.sys.is_mdy ?  'M/D/YY h:mm a': 'D/M/YY h:mm a';

	return <span title={m.format(buf)}>
		{display_time}
	</span>
}

function blogdate(isodate) {
	if (!isodate)
		return null;
	const m = moment(isodate);
	const buf = ldb.data.sys.is_mdy ? 'MMM Do' : 'Do MMM';
	return <div className="blogdate">
		 <div className="blogdate_date">
			{m.format(buf)}
		</div>
		 <div className="blogdate_time">
		{m.format('h:mm a')}
		</div>
	</div>
}

function svg_icon(klass, text, title, defy=9) {
	return <svg className={'svg-icon ' + klass}
			title={title}
		>
			<title>{title}</title>
			< text x={0} y={defy}>
				{text}
			</text>
		</svg>;
}

function mbox_read_perm() {
	if ((ldb.data.me.account_type == 'n') || (ldb.data.me.account_type == 'p') || (ldb.data.me.account_type == 'r')) {
		return true;
	}
	return false;
}

function mbox_write_perm() {
	if ((ldb.data.me.account_type == 'n') || (ldb.data.me.account_type == 'p')) {
		return true;
	}
	return false;
}

function mbox_move_perm() {
	if (ldb.data.me.account_type == 'n') {
		return true;
	}
	return false;
}

function is_super_admin() {
	if (ldb.data.me.account_type == 's') {
		return true;
	}
	return false;
}

function get_room_team_email(room) {
	const staffs = room.staffs;
	
	let team_email = null;

	staffs._idlist.forEach(function(sid) {
		const staff = get_staff(sid);
		
		if (staff.is_team_email)
			team_email = staff.email;
	});
	
	return team_email;
}

function get_room_team_email_display_name(room) {
	const staffs = room.staffs;
	
	let display_name = null;

	staffs._idlist.forEach(function(sid) {
		const staff = get_staff(sid);
		
		if (staff.is_team_email)
			display_name = staff.name + ' (' + staff.email + ')';
	});
	
	return display_name;
}

function room_has_team_email(room) {
	const team_email = get_room_team_email(room);
	
	if (team_email)
		return true;
	
	return false;
}

function any_room_has_team_email() {
	let room = null;
	let room_has = false;

	for (let rid in ldb.data.rooms._items) {
		room = ldb.data.rooms._items[rid];
		room_has = room_has_team_email(room);
		if (room_has)
			return true;
	}
	
	return false;
}

function get_all_rooms_with_client_email(email) {
	let room_list = [];

	ldb.data.rooms._idlist.forEach(rid => {
		let room = get_room(rid);
		
		room.clients._idlist.forEach( cid => {
			let client = room.clients._items[cid];
			
			client.emails._idlist.forEach( eid => {
				let c_email = client.emails._items[eid];
				
				if (c_email.email == email) {
					let rid = room.id;
					if (!room_list.includes(rid)) {
						room_list.push(rid);
					}
				}
			});
		});
	});
		
	return room_list;
}

function get_all_client_rooms(client) {
	let room_list = [];

	client.emails._idlist.forEach( eid => {
		let c_email = client.emails._items[eid];
		
		let e_room_list = get_all_rooms_with_client_email(c_email.email);
		e_room_list.forEach( rid => {
			if (!room_list.includes(rid)) {
				room_list.push(rid);
			}
		});
	});
	
	return room_list;
}

function is_room_a_cimr_room(room) {
	let is_cimr_room = false;
	
	room.clients._idlist.forEach( cid => {
		const client = room.clients._items[cid];
		
		const room_list = get_all_client_rooms(client);
		if (room_list.length > 1) {
			is_cimr_room = true;
		}
	});
	
	return is_cimr_room;
}

function is_my_tag_read(tag) {
	if (tag.to_sid != ldb.data.me.id) {
		return true;
	}
	
	if (tag.dt_viewed) {
		return true;
	}
	
	return false;
}

function is_tag_read(tag) {
	if (tag.dt_viewed) {
		return true;
	}
	
	return false;
}

function get_num_tags(only_unread=false) {
	let found_tags = [];
	
	const keys = Object.keys(ldb.data.tags._items);
	keys.forEach(key => {
		const item = ldb.data.tags._items[key];
		if (((!only_unread) || (!is_tag_read(item))) && (item.to_sid == ldb.data.me.id)) {
			found_tags.push(item);
		}
	});
	
	return found_tags.length;
}

function env_has_my_unread_tags(env) {
	let has_unread_tags = false;

	const tags = env_tags(env);
	tags.forEach(tag => {
		if ((!is_tag_read(tag)) && (tag.to_sid == ldb.data.me.id)) {
			has_unread_tags = true;
		}
	});
	
	return has_unread_tags;
}

function show_is_new(flag, title, text) {
	/*
	let cls = "fa fa-fw fa-plus-square new";
	if (!flag)
		cls += ' invisible';
	return <i title="New Item since Login" className={cls} />
	const cls = '';
	*/
	const cls = flag ? '' : 'invisible';
	if (!flag)
		return null;
	title = title || 'New';
	text = text || 'New';
	return <svg className={cls}
			height={12} width={text.length * 7} fill="red"
			title={title}
		style={{fontSize:'8px', background:'moccasin', 
			padding:'0 0.25em 0 0.25em',}}
		>
			<title>{title}</title>
			< text x={0} y={10}>
				{text}
			</text>
		</svg>;
}

function is_within_one_day(d) {
	if (!d) {
		return false;
	}

	const current_date = new Date();
	
	const t2 = current_date.getTime();
	const t1 =  Date.parse(d);
	
	const difference_in_days = parseInt((t2-t1) / (24*3600*1000));
	
	// console.log(difference_in_days);
	
	if (difference_in_days == 0) {
		return true;
	} else {
		return false;
	}
}

function has_passed(d) {
	if (!d) {
		return false;
	}

	const current_date = new Date();
	
	const t2 = current_date.getTime();
	const t1 =  Date.parse(d);
	
	const difference_in_days = parseInt((t2-t1) / (24*3600*1000));
	
	// console.log(difference_in_days);
	
	if (difference_in_days > 0) {
		return true;
	} else {
		return false;
	}
}

const unset_priority = 50;
const priorities = [
	{label: 'Low', value: 40},
	{label: 'Normal', value: 30},
	{label: 'High', value: 20},
	{label: 'Urgent', value: 10},
];

function pri_n2v(name) {
	const sel = priorities.filter(i => i.label == name);
	return sel[0] ? sel[0].value : 0;
}

function pri_v2n(value) {
	const sel = priorities.filter(i => i.value == value);
	return sel[0] ? sel[0].label : '';
}

function get_next_prev_ids(order, id) {
	const index = order.indexOf(id);
	var next_id, prev_id;

	next_id = prev_id = 0;
	if (index >= 0) {
		prev_id = index > 0 ? order[index - 1] : 0;
		next_id = index < (order.length -1) ? order[index + 1] : 0;
	}

	// log('lib', 'next/prev', id, next_id, prev_id, order);

	return {next_id, prev_id};
}

function get_next_prev_eids(email) {
	const order = ldb.data.folders[email.fid].meta.order;

	return get_next_prev_ids(order, email.id);
}

function is_in_viewport(el) {
    const top = el.getBoundingClientRect().top;
    return top >= 0 && top <= window.innerHeight;
}

function save_growl_notification(kind, title, body) {
	if (!ldb.data.growl_notifications) {
		ldb.data.growl_notifications = [];
	}
	const dt = moment();
	ldb.data.growl_notifications.push({title, body, kind, dt});
}

function get_previous_versions() {
	return Version.slice(1);
}

function get_all_versions() {
	return Version;
}

function org_admins_display() {
	let output = '';
	let sep = '';

	ldb.data.staffs._idlist.forEach(sid => {
		let s = get_staff(sid);
		output = output + sep + s.name;
		sep = ', ';
	});
	
	return output;
}

/*============== Room MRU (Most Recently Used) ===============*/

class RidMRU {
	// Overloaded to also POLL for Pending Emails
	constructor() {
		this.nmax = 10;
		this.mru = [];
		this.rids = new Set();
		this.order = [];

		this.timer = null;
		this.count = 0;

	}
	// Add rid to the list if it isn't already there.
	//	Keep only Most Recent nmax items
	//	Sort them by name
	add = rid => {
		rid = parseInt(rid, 10);
		if (this.rids.has(rid))
			return false;

		this.mru.push(rid);
		this.rids.add(rid);
		this.order.push(rid);

		if (this.mru.length > this.nmax) {
			const rid = this.mru.shift();
			this.rids.delete(rid);
			list_rm_ele(this.order, rid);
		}

		const items = ldb.data.rooms._items;
		this.order.sort(compare_items(items, sort_room_by_name));

		// log('room', 'MRU', rid);

		// TBD... this gives a warning about refreshing in render,
		//	which we don't. TBD. see if this is really needed.
		// window.g_appMenu.refreshMenu();

		return true;
	}

	idle_check = () => {
		log('lib', 'visibility', document.visibilityState);
	}

	poll = () => {
		if (window.g_noPoll)
			return;

		// log('room', 'Poll', this.count++);
		if (api_in_progress)
			return;

		if (this.mru.length == 0)
			return;

		this.idle_check();

		const rid = this.mru[ this.mru.length -1 ];
		
		const args = {
			cmd: 'get_list', tab: 'unshared', 
			rid, from_id : -1, till_id: 0,
		}
		log('room', 'Poll', this.count++);
		api( args, this.polled, this.polled );
	}

	polled = (error, db, resp) => {
	}

	init = () => {
		const root = ldb.data.rooms;
		const order = root._idlist.slice();
		order.sort(compare_items(root._items, sort_room_by_visited));
		for (let i=0; i<this.nmax && i<order.length; i++)
			this.add( order[i] );

		if (this.timer)
			clearInterval(this.timer);
		this.timer = setInterval( () => {this.poll()}, 
					REFRESH_SECS * 1000 );
	}
}

const ridMRU = new RidMRU();
window.g_ridMRU = ridMRU;

// With new architecture, polling is no longer needed. Remove. TBD TBD
window.g_noPoll = true;

/*============== Idle Check ===============*/

// IdleTracker
//	Hooks to IdleTimer NPM package that checks browser idleness
//	App.js calls this one.
//	Used to notify server of Staff idle status in Presence.
class IdleTracker {
	constructor() {
		// this.idle_timeout_seconds =  5;
		// this.idle_timeout_seconds = 5 * 60;
		this.idle_timeout_seconds = 15 * 60;
		this.is_idle = false;
		this.count = 0;
	}

	onActive = () => {
		if (!this.is_idle)
			return;
		this.is_idle = false;
		// growl('Idle', 'Active');
		//this.send_idle(0);
	}

	onIdle = lastActive => {
		if (this.is_idle)
			return;
		const seconds = Math.round( (Date.now() - lastActive) / 1000 );
		this.is_idle = true;
		// growl('Idle', 'Idle: ' + seconds);
		//this.send_idle(seconds);
	}

	send_idle = seconds => {
		const args = {
			cmd: 'idle', seconds,
		}
		log('ldb', 'idle', seconds, this.count++);
		api( args, this.sent_idle, this.sent_idle );
	}

	sent_idle = (error, db, resp) => {
	}

}

const idleTracker = new IdleTracker();
window.g_idleTracker = idleTracker;



class IdlePoll {
	constructor() {
		//this.idle_timeout_seconds = 5;
		this.idle_timeout_seconds = 5 * 60;
		//this.idle_timeout_seconds = 15 * 60;
		this.timer = null;
		this.count = 0;
	}

	start_polling = () => {
		if (this.timer) {
			clearInterval(this.timer);
		}
		
		this.timer = setInterval( () => {this.send_idle()}, (this.idle_timeout_seconds * 1000) );
	}
	
	send_idle = () => {
		const seconds = 0;
		
		const args = {
			cmd: 'idle', seconds,
		}
		log('ldb', 'idle_poll', this.count++);
		api( args, this.sent_idle, this.sent_idle );
	}
	
	sent_idle = (error, db, resp) => {
	}
}

function begin_idle_poll() {
	log('ldb', 'begin idle_poll');
	
	const idlePoll = new IdlePoll();
	window.g_idlePoll = idlePoll;

	window.g_idlePoll.start_polling();
}

/*============== Refresh Utilities ===============*/

class IsNew {
	constructor() {
		this.now = moment();
	}
	reset = () => this.now = moment()
	
	is_new = (when) => {
		if (when === undefined)
			return false;
		const ms = this.now - when;

		log('ldb', 'isNew', ms);

		return ms < 10000;
	}
}

const isNew = new IsNew();


//----
const REFRESH_SECS = 120;
// const REFRESH_SECS = 5;
function set_next_fetch_time(list, delta) {
	if (delta === undefined)
		delta = REFRESH_SECS*1000; 
		
	list._flags.next_fetch_time = Date.now() + delta;
}

//----

let g_newActivity = 0;
let g_newCount = 0;
const act_tabs = ['shared', 'task'];

function set_new_activity_count() {
	let count = 0;

	foreach_list(ldb.data.rooms, room => {
		if (has_activity(room))
			count++;
	});

	if (count != g_newCount) {
		g_newCount = count;
		window.g_appTopbar.set_active_room_count( count );
		// TBD: May be run this once rooms are filled?
		// window.g_appMenu.refreshMenu(); 
	}

	g_newActivity = 0;
}

/*============== Mobile Utilities ===============*/

/*============== UI Utilities ===============*/

function selenium_user(msg) {
	const me = ldb.data.me;
	if (me && me.email.indexOf('.sel@c') >= 0) {
	// if (me && me.email.indexOf('.agency@c') >= 0) {
		log('lib', '--selenium growl disabled', msg);
		return true;
	}
	return false;
}

function using_selenium_test_email() {
	if (ldb.data.me) {
		if (ldb.data.me.email.includes('.sel@c')) {
			return true;
		}
	}
	
	return false;
}

function growl(title, body, kind, sticky, life) {
	kind = kind || 'success';
	if (sticky === undefined)
		sticky = false;
	
	life = life || 3000;
	if (kind == 'error') {
		life = 30000;
	}

	if (using_selenium_test_email())
		return;

	save_growl_notification(kind, title, body);
	
	// TBD Add a growl error counter that increments when a growl
	// error is added and decreases when you call onRemove on the Growl
	// object. If there is a queue then show a message directing the user
	// to the notifications list instead of displaying each error.

	// TBD Figure out a way to make the whole growl notification
	// clickable to make it disappear.

	// if (window.is_mobile)
		// return window.g_mfoot.growl( title );

	const obj = window.g_growl;
	// if (!obj || !obj.current) {
	if (!obj) {
		log('lib', 'growl not set', obj);
		return;
	}
	if (!selenium_user(title))
		// obj.current.show.call(obj, {
		obj.show.call(obj, {
			severity: kind,
			summary: title,
			detail: body,
			sticky: sticky,
			life: life,
		});
}

/*============== Input / Prime utilities ===============*/

function foreach_list(list, fn, self) {

	list._idlist.forEach( (id) => (
		fn(get_root_item(list, id), id)
	), self);
}

function map_list(list, fn, self) {
	if (!list)
		return [];
	
	const seq = list._order || list._idlist;

	return seq.map( (id, index, orig) => {
		return fn(get_root_item(list, id), index, orig);
	}, self);
}

function filter_list(list, fn, self) {
	const res = [];

	foreach_list(list, item => {
		if (fn(item))
			res.push(item);
	}, self);

	return res;
}

function select_employees() {
	const options = [];

	foreach_list(ldb.data.members, (member) => {
		if (member.is_staff)
			options.push({label: member.name, value: member.id});
	});

	return options;
}

function foreach_dict(root, fn, self) {
	// This doesn't expect _order, _idlist to exist in root
	//	unlike foreach_list above.
	Object.keys(root._items).forEach(id => fn( root._items[id] ), self);
}

function copy_to_clipboard(buf) {
	const ele = document.createElement('textarea');
	ele.value = buf;
	document.body.appendChild(ele);
	ele.select();
	document.execCommand('copy');
	document.body.removeChild(ele);

	return false;
}

/*============== Debug utilities ===============*/

function pint(buf) {			// shortcut for parseint for 10 base
	if (buf === undefined)
		return 0;
	return parseInt(buf, 10);
}

function get_tag(tid) {
	return ldb.data.tags._items[parseInt(tid, 10)];
}

function get_tag_by_me(tid) {
	return ldb.data.tags_by_me._items[parseInt(tid, 10)];
}

function env_tags(env) {
	if (!env.tag_ids)
		return [];
	return env.tag_ids.split(',').map(get_tag);
}

function tag2env(tag) {
	return get_item(tag.rid, 'shared', tag.eid);
}

function get_room(rid) {
	return ldb.data.rooms._items[parseInt(rid, 10)];
}

function url2iid(hash) {
	hash = hash || window.location.hash;
	const exp = /^#\/room\/\d+\/\w+\/(\d+)/;
	const m = hash.match(exp);
	if (m && m.length > 1) {
		return parseInt(m[1], 10);
	}
	return 0;
}

function url2rid(hash) {
	hash = hash || window.location.hash;
	const exp = /^#\/room\/(\d+)\//;
	const m = hash.match(exp);
	if (m && m.length > 1) {
		return parseInt(m[1], 10);
	}
	return 0;
}

function url2room(hash) {
	const rid = url2rid(hash);
	return rid ? get_room(rid) : null;
}

function get_connection(cid) {
	return ldb.data.contacts.connections._items[parseInt(cid, 10)];
}

function get_room_name(rid) {
	return rid ?  get_room(rid).name : '';
}

function get_list(rid, tab) {
	return get_room(rid)[tab];
}

function get_item(rid, tab, iid) {
	const list = get_list(rid, tab);
	if (list)
		return list._items[iid];
	return null;
}

function get_first_id(rid, tab) {
	let first_id;
	const list = get_list(rid, tab);

	if (!list)	// summary
		return 0;

	const total = list._flags.page.total;
	// -1 => not fetched, 0 = no items. check init  tab  db

	if (total <= 0)
		first_id = total;	
	else if (list._order.length)
		first_id = list._order[0];	// got list
	else if (list._flags.page.has_more)
		first_id = -1;			// fetch more
	else
		first_id = 0;			// empty list

	return first_id;
}

function get_task(tid) {
	return ldb.data.tasks._items[tid];
}

function get_task_by_me(tid) {
	return ldb.data.tasks_by_me._items[tid];
}

function get_staff(sid) {
	// tasks: get_staff(task.mid).name .. return empty obj
	//	so that won't break.
	return ldb.data.staffs._items[sid] || {name: ''};
}

function d_emails(rid) {		// ??? TBD ??? accidentally truncated?
	return get_room(rid).emails;
}

function get_room_email(rid, eid) {
	return get_room(rid).emails._items[eid];
}

function get_room_task(rid, tid) {
	return get_room(rid).task._items[parseInt(tid)];
}

function get_email(eid) {
	return ldb.data.emails._items[eid];
}

function get_my_feedback(fid) {
	return ldb.data.my_feedback._items[fid];
}

function is_current_room(rid) {
	rid = parseInt(rid);
	if (rid == 0)
		return true;

	let cur = 0;
	const parts = window.location.hash.split('/');
	if (parts[1] == 'room')
		cur = parts[2];
	if (cur)
		return cur == rid;
	return false;
}

function get_current_room() {
	let cur = 0;
	const parts = window.location.hash.split('/');
	if (parts[1] == 'room')
		cur = parts[2];
	if (cur)
		return get_room(cur);
	return null;
}

function get_prev_room() {
	const current_room = window.g_currentRoom;
	if (!current_room) {
		return null;
	}
	
	const order = ldb.data.rooms._order;

	const cur_index = order.indexOf(current_room.id);
	if (cur_index < 0) {
		return null;
	}
	
	const prev_index = cur_index - 1;
	if (prev_index < 0) {
		return null;
	}
	
	const prev_rid = order[prev_index];
	const prev_room = get_room(prev_rid);
	
	return prev_room;
}

function get_next_room() {
	const current_room = window.g_currentRoom;
	if (!current_room) {
		return null;
	}
	
	const order = ldb.data.rooms._order;

	const cur_index = order.indexOf(current_room.id);
	if (cur_index < 0) {
		return null;
	}
	
	const next_index = cur_index + 1;
	if (next_index >= order.length) {
		return null;
	}
	
	const next_rid = order[next_index];
	const next_room = get_room(next_rid);
	
	return next_room;
}

function get_bulk_email_list(bid) {
	return ldb.data.bulk_email_lists._items[parseInt(bid, 10)];
}

function get_bulk_email_list_data(bid) {
	const bulk_email_list = get_bulk_email_list(bid);
	
	let rcount = 0;
	let ccount = 0;
	let ecount = 0;
	
	if (bulk_email_list.data_json == '{}') {
		return {'rcount': rcount,
			'ccount': ccount,
			'ecount': ecount
		}
	}
	
	const included_eids = bulk_email_list.data.included_eids;

	bulk_email_list.data.rids.forEach( rid => {
		rcount++;

		const room = get_room(rid);

		room.clients._idlist.forEach( cid => {
			let efound = false;
			
			const client = room.clients._items[cid];
			
			client.emails._idlist.forEach( eid => {
				if (included_eids.includes(eid)) {
					efound = true;
					ecount++;
				}
			});

			if (efound) {
				ccount++;
			}
		});
	});
	
	return {'rcount': rcount,
		'ccount': ccount,
		'ecount': ecount
	}
}

function dv_default_email_recipients(value) {
	if (value == 'a') {
		return 'All Clients';
	}
	if (value == 's') {
		return 'Select Clients';
	}
	if (value == 'n') {
		return 'No Clients';
	}
	
	return '';
}	




/*============== Globals ===============*/

window.room = get_room;
window.emails = d_emails;
window.email = get_email;
window.staff = get_staff;
window.rtask = get_room_task;

window.subitem = (rid, tab, iid) => ldb.data.rooms._items[rid][tab]._items[iid]
window.rtask = (rid, tid) => window.subitem(rid, 'task', tid)
window.rshared = (rid, iid) => window.subitem(rid, 'shared', iid)
window.runshared = (rid, iid) => window.subitem(rid, 'unshared', iid)

/*============== Log ===============*/

const lcats = {
	icc: true, db: true, socket: true, api: true, login: true, lib: true,
	any: true, email: true, room:true, ldb: true, task: true, app: true,
	listview: true, unshared: true, shared: true, user:true, logs:true,
	chat:true, mytasks: true, search: true, online: true, org: true,
	bulk: true, menu: true, checklist: true,
	mkroom:true, mcast: true, mobile:true, person:true, tag: true,
	vault: true,
};

function log(cat) {
	if (lcats[cat]) {
		// show category
		return console.log.apply(console, arguments);
		// remove "cat" and send rest of the arguments to console log.
		// return console.log.apply(console, [...arguments].slice(1));
	}
	return null;
};

/*============== ICC Inter Component Communication ===============*/
/*
 * ICC / IPC
 *	Simple communication means between components.
 * Listen (name, callback, this)
 *	will get called for each message sent for this "name".
 *	There can be multiple listeners for the same "name".
 *     Returns ID that can be used to cancel.
 * Send(name, data)
 *	Sends data to each of the listeners.
 *	If there is no listener, hold data in a queue for that channel.
 * Cancel(id)
 *	ID = JSON array with 0=Channel Name, 1=IpcId
 *
 * If someone sends to a channel, before there is a listener
 * 	that message is dropped.
 */

var icc_channels = {};	// local channels, not django-channels
var icc_count = 0;
var id2channel = {};

function icc_listen(name, callback, othis) {
	var item, itemid;

	itemid = gen_id('icc');

	item = { othis: othis, callback: callback, id: itemid};
	
	if (!icc_channels[name])
		icc_channels[name] = [];

	icc_channels[name].push(item); 

	log('icc', "listen", name, itemid);

	id2channel[itemid] = name;

	return itemid;
};

function icc_send(name, data) {
	var channel, i, channels, ret, iret, count;

	count = icc_count++;

	log('icc', count + " send>>", name, data);

	channels = icc_channels[name];
	if (!channels) 
		return false;

	ret = true;
	for (i=0; i<channels.length; i++) {
		channel = channels[i];
		iret = channel.callback.apply( channel.othis, [data] );
		ret = ret & iret;
	}

	log('icc', count + " send<<", name, data, ret);
	return ret;
};

function icc_cancel(itemid) {
	var i, channels, channel, name;

	name = id2channel[itemid]; 

	log('icc', "cancel>>", itemid);
	channels = icc_channels[name];
	if (!channels)
		return false;

	for (i=0; i<channels.length; i++) {
		channel = channels[i];
		if (channel.id === itemid) {
			channels.splice(i, 1);
			return true;
		}
	}
}

function current_room_alert(data) {
	const dlog = data.log;
	if(is_current_room(data.rid) && dlog !== undefined){
		if (typeof(dlog) == 'object') 
			growl(dlog.title, dlog.message,
				dlog.kind, dlog.sticky,
				dlog.life);
		else if (dlog && dlog.charAt(0) != '_')
			growl('Update', dlog);
			// log starting with __ to be ignored
			// It is comment for debugging.
	}
}

/*============== WebSocket ===============*/

function reqcmd(obj) {
	let cmd = '';
	try {
		const req = obj.request || api_log[obj.id].request;
		cmd = req.cmd;
	}
	catch {
		cmd = '?';
	}
	return cmd;
}

class Sock {
	constructor() {
		this.web_socket = null;
		this.url = this.set_url();

		this.state = 'new';
		this.reconnecting = false;
		this.cred = '';

		this._wsq = [];
		this.last_received = '';	// debug, last received data

		// No Reconnection.. disabled for now.
		//	Let user reconnect and get fresh data.
		this.reconnect_count = 0;
		this.reconnect_max = 0;
		this.reconnect_delay = [1, 5, 10, 1*60, 5*60, 10*60];	// secs

		// check if we're live.
		//	Browsers can take 30 sec to 10 min to tell
		//	socket is closed. So we start timer on req,
		//	and 
		//	
		// Set g_test_no_response for simulating conn loss
		this.live_timeout_secs = 16;
		this.live_timer = null;

		// Ok if first 12 APIs take too long. Login, etc.
		//	might take a while.
		this.live_grace_count = 12; 

		this.live_status = 'black';
		this.live_url = '/';
		// black: init, green: online, red: offline, orange: offline??
	}

	set_url = () => {
		const ws_scheme = window.location.protocol == 'https:' ? 
					'wss' : 'ws';
		const port = window.location.port ? ':8000' : '';

		const url = ws_scheme + '://' + window.location.hostname + 
					port + 
					'/ws/tube/';
		return url;
	}

	connect = () => {
		if (this.web_socket !== null) {
			log('socket', "Already Connected", this.url);
			return;
		}


		log('socket', "Connect...", this.url);

		const ws = new WebSocket(this.url);
		ws.onclose = this.onClose;
		ws.onopen = this.onOpen;
		ws.onmessage = this.onMessage;
		ws.onerror = this.onError;
		ws.addEventListener('error', ev => {
			window.g_sock.onError(ev);
		});

		this.web_socket = ws;
		this.state = 'pre_connect';

		// Javascript is single threaded... 
		//	so connection won't be initiated
		//	until this routine completes.
	}

	onOpen = () => {
		// readystate: 0 : connecting, 1: open, 2: closing, 3: closed
		log('socket', 'onOpen', this.web_socket.readyState);
		if (this.web_socket.readyState == 1) {
			this.state = 'connected';
			this.live_status = 'green';
			if (this.reconnecting)
				this.do_relogin();
			else
				this.send_pending();
		}
	}

	onMessage = (e) => {
		const data = JSON.parse(e.data);

		this.last_received = data;
		const cmd = reqcmd(data);

		log('socket', ">>> Received", cmd, data);

		switch(data.type) {
		case 'api_reply':
			try {
				api_done(data);
			}
			catch(error) {
				console.error(error);
			}
			this.live_timer_stop();
			break;

		case 'multicast':
			ldb.load(data.db, data);
			handle_action(data.db.action);
			current_room_alert(data);
			if (data.kind == 'notification')
				safe_my_rooms_refresh();

			// If timer is running, ie pending api request,
			//	restart it, since receiving multicast means
			//	connection is alive. give api more time.
			if (this.live_timer)
				this.live_timer_restart();
			break;
		
		default:
			log('socket', 'unknown socket message');
			break;
		}
		log('socket', "<<< Received", cmd, data);
	}

	send = (buf) => {
		const payload = JSON.stringify({ 'message' : buf });

		if (this.state != 'connected' ) {
			this._wsq.push(payload);
			if (this.state == 'new')
				this.connect();
		}
		else {
			// Try catch is not working to catch send errors
			//	onerror is not working either.
			try {
				this.web_socket.send( payload );
			}
			catch(e) {
				return this.onError(e);
			}
			if (this.conn_lost()) {
				return this.onError('Socket Closed');
			}
			const cmd = reqcmd(buf);
			log('socket', "Send", cmd, buf);
			this.live_timer_start();
			this.live_url = window.location.hash;
		}
	}

	close = () => {
		this.web_socket.close(1000, 'Signing out');
		this.web_socket = null;
		this.live_status = 'black';
		this.live_url = '/';
	}

	onClose = (e) => {
		console.log("socket closed");
		return this.live_timer_failed();

		// Use commomn path for reconnection, so
		//	it goes back to current URL.
		// Skip old code below, 
		console.log("socket closed");
		this.close();


		if (this.reconnect_count < this.reconnect_max) {
			this.state = 'closed';
			this.reconnecting = true;

			const delay = this.reconnect_delay[
					this.reconnect_count];
			log('socket', 'Reconnect...', this.reconnect_count,
					'after seconds:', delay);
			setTimeout(() => sock.connect(), delay * 1000);
			this.reconnect_count++;
		}
		else {
			this.state = 'lost';
			this.save_reconnect_url();

			window.location = '/#/reconnect/lost';
		}
	}

	onError = e => {
		log('socket', 'ERROR', e);
		this.onClose(e);
	}

	//--- LIVE check >>

	live_timer_start = () => {
		this.live_timer_stop();
		return;

		// ***** SKIP Timer Work for now...
		this.live_timer = setTimeout(this.live_timer_failed,
					this.live_timeout_secs * 1000);
		log('socket', 'LIVE: timer start');

		// Ignore timeout for the first few API requests.
		//	they take too long. We know server is alive,
		//	otherwise we wouldn't have started.
		if (this.live_grace_count > 0)
			this.live_grace_count--;
	}

	live_timer_stop = () => {
		if (this.live_timer) {
			clearTimeout(this.live_timer); 
			log('socket', 'LIVE: timer stop ');
		}
		this.live_timer = null;
	}

	live_timer_restart = () => {
		this.live_timer_start();
	}

	// 0 = connecting, 1 = open, 2 = closing, 3 = closed
	conn_lost = () => (this.web_socket.readyState > 1);

	live_timer_failed = () => {
		const cmd = reqcmd({id: api_index - 1});
		const lost = this.conn_lost();
		// go through 2 stages later.
		if (this.live_grace_count > 0 && !lost) {
			log('socket', 
			'IGNORING, LIVE: API took too long', 
				cmd,
				this.live_url, 
				api_index,
				this.live_grace_count);

			this.live_timer_stop();
			return;
		}

		const emsg = lost ? 'Connection Lost' : 'API took too long';

		log('socket', 'LIVE:', emsg, cmd, this.live_url, api_index);

		this.live_status = 'red';
		growl('Error', 'Lost Server Connection');
		go('reconnect', 'lost');
	}

	//--- recon url >>

	recon_cookie_name = () => 'cwrecon_' +  
			(ldb.data.me ? ldb.data.me.id : 0)

	save_reconnect_url = () => {
		// Save current url, before we change it to /#/reconnect,
		//	so when user clicks Reconnect and we do,
		//	we can retrieve this from the cookie and resume
		//	from where we left off.
		//  Cookie expires when browser closes. (default for expires)
		//	still remmeber this for current sid, if relogin
		//	for diff staff, we won't use this.

		const url = window.location.hash;
		const key = this.recon_cookie_name();
		Cookies.set(key, url);

		log('login', 'save reconnect url', key, url);
	}

	get_reconnect_url = () => {
		const key = this.recon_cookie_name();
		let url = Cookies.get(key) || '';
		if (url) {
			Cookies.set(key, '');	// erase it, for future logins
			log('login', 'get_reconnect_url', url);
		}
		return url;
	}

	//--- recon url <<

	send_pending = () => {
		for (;;) {
			const item = this._wsq.shift();
			if (item === undefined)
				break;
			this.send(item);
		}
	}

	save_for_relogin = (email, password) => {
		// TBD: find a better way in future, where 
		//	we don't store user credentials, 
		//	but a timestamped login cookie or such
		this.cred = btoa(JSON.stringify({email, password}));
	}

	get_relogin_info = () => {
		const cred = JSON.parse(atob(this.cred));
		const {email, password} = cred;
		return {email, password};
	}

	do_relogin = () => {
		if (!this.cred)
			return;
		const jcred = atob(this.cred);
		if (!jcred)
			return;
		const cred = JSON.parse(jcred);
		const {email, password} = cred;
		const cmd = 'login';
		const relogin = true;
		const args = {cmd, email, password, relogin};

		log('login', 'relogin', cmd, email);

		api( args, this.cb_relogin, this.cb_relogin);

	}

	cb_relogin = (error) => {
		if (error) {
			log('login', 'relogin failed', error);
			this.state = 'failed';
			growl('Error', 'Auto relogin failed: ' + error, 
					'error');
			return;
		}
		this.reconnecting = false;
		this.reconnect_count = 0;	// reset for future disconnects
		this.send_pending();
	}
}

var sock = new Sock();
window.g_sock = sock;
const get_reconnect_url = sock.get_reconnect_url;

function socket_reconnect() {
	sock.connect();
}

function is_local_server() {
	return window.location.protocol != 'https:';
}

function is_test_server() {
	if (window.location.host.indexOf('taginbox.com') >= 0) {
		return false;
	}
	
	if (window.location.host.indexOf('clientwin.com') >= 0) {
		return false;
	}
	
	return true;
}

/*============== API ===============*/

var api_index = 0;
var api_log = {};
var api_q = [];		// One at a time, chain multiple reqs
var api_in_progress = false;	

window.g_api_log = api_log;
window.g_api_index = api_index;
window.g_api_q = api_q;

// for test prep, simulation of silent connection loss
//	Check sock.live_timer 
window.g_test_no_response = false;

function api(request, callback, error_callback) {

	if (api_in_progress) {
		log('api', '++QUEUE', request.cmd);
		api_q.push({request, callback});
		// If previous api has not completed because socket is closing,
		//	then raise error
		if (sock.conn_lost()) {
			sock.onError('Socket Closed');
		}
		return;
	}
	api_in_progress = true;

	const id = api_index++;
	request.sid = ldb.data.me ? ldb.data.me.id : 0;
	request.xid = ldb.data.xid;

	if (window.g_test_no_response) {
		request.test_no_response = true;
		log('api', '+++++ TEST NO RESPONSE');
	}

	const item = {type: 'api_call', id, request};
	sock.send(item);
	item.dt_req = new Date();

	log('api', '=====>>>>> request', request.cmd, item);

	item.callback = callback;
	item.error_callback = error_callback;
	item.dt_start = new Date();
	api_log[id] = item;

	window.g_api_index = api_index;
}

function api_done(resp) {
	const req = api_log[resp.id];
	const error = resp.error;

	req.dt_end = new Date();
	const dur = req.dt_end - req.dt_start;

	// Debug
	req.resp = resp;

	log('api', '<<<<<===== reply', 
			req.request.cmd, error, resp.error_details, resp,
			dur, "msec");

	if (!resp.request)
		resp.request = req.request;

	if (resp.db)
		ldb.load(resp.db, req);

	if (error) {
		if (req.error_callback !== undefined)
			req.error_callback(error, resp.db, resp)

		if (error == 'CwError' || error == 'AssertionError')
			growl('Error', resp.error_details, 'warn');
		else
			growl('Error', 'Network Error: ' + error, 'error');
		// TBD: Should call req.callback if error_callback is not defined.
	}
	else {
		handle_action(resp.db.action);
		if (req.callback !== undefined)  {
			req.callback(error, resp.db, resp);
		}
	}

	// delete api_log[reply.id];

	api_in_progress = false;
	const pending = api_q.shift();
	if (pending !== undefined) {
		log('lib', '----pending api', pending);
		api(pending.request, pending.callback);
	}
	log('api', 'DONE <<<<<===== reply', req.request.cmd);
}

/*============== Cached Data ===============*/

// global
window.g_current_list_view = {rid: 0, tab: '', comp: null, iid: 0, command: ''};

function get_current_list_view_rid() {
	const {rid, tab, comp} = window.g_current_list_view;
	
	return rid;
}

function get_current_list_view_tab() {
	const {rid, tab, comp} = window.g_current_list_view;
	
	return tab;
}

function get_current_list_view_comp() {
	const {rid, tab, comp} = window.g_current_list_view;
	
	return comp;
}

function set_current_list_view(comp, newprops) {
	if (comp) {	// set
		// default comp.props, unless specified explicitly
		const props = newprops || comp.props;
		const {rid, tab, iid, command} = props;
		window.g_current_list_view = {rid, tab, comp, iid, command};
	}
	else {		// reset
		window.g_current_list_view = {rid: 0, tab: '', comp:null,
				iid: 0, command: ''};
	}
	log('lib', 'set_current_list_view', comp, newprops);
}

function refresh_tab_if_current(nrid, ntab) {
	const {rid, tab, comp} = window.g_current_list_view;
	if (rid == nrid && tab == ntab && comp && comp._isMounted)
		comp.refresh();
}

function safe_my_rooms_refresh() {
	// g_rooms_refresh supercedes this.
	// if (window.g_Rooms && window.g_Rooms._isMounted)
		// window.g_Rooms.refresh();

	log('room', 'MyRooms Refresh');

	if (window.g_miniRooms && window.g_miniRooms._isMounted)
		window.g_miniRooms.refresh();

	if (window.g_rooms_refresh)
		window.g_rooms_refresh();
}

function copy_task_to_mytasks_if_for_me(rid, tid) {
	const task = get_room_task(rid, tid);
	if (!task)
		return false;

	// 3 cases: 1. new task for me, 2. my task updated, 3. my task done

	const is_my_task = (task.to_sid == ldb.data.me.id)
	// existing my task, updated. still to === me, or 0 (done).
	const my_task_updated = (ldb.data.tasks._items[tid] !== undefined);

	if (my_task_updated && !is_my_task) {
		// what was my task, is assigned to someone else
		//	and no longer mine.
		list_rm_item(ldb.data.tasks, tid);
		log('lib', 'Remove task from MyTasks', task);
		return true;
	}
	else if (is_my_task || my_task_updated) {
		log('lib', 'copy task to my', task);
		task.kind = 'task';

		const target = ldb.data.tasks;
		target._items[tid] = {...task};
		const source = {'_items' : {}};
		source._items[tid] = task;
		postmerge('_items', source, target, null, []);
		return true;
	}
	return false;
}

function update_multicast_channels() {
	const args = {
		cmd: 'profile_update', 
		op: 'update_multicast_channels',
	}
	api( args );
	// TBD : Handle errors
	log('lib', 'Update Multicast Subscription Channels');
}

//------------- Multicast actions

function task_updated_mcast(data) {
	const {rid, tab, comp} = window.g_current_list_view;
	const drid = parseInt(data.rid, 10);
	let show = false;

	if (tab == 'mytasks')
		show = false;
	else if (rid == drid) {
		if (tab == 'task')
			show = true;
		else if (tab == 'shared' && parseInt(data.uid))
			show = true;
	}
	const mod = copy_task_to_mytasks_if_for_me(drid, data.id);

	log('lib', 'task_updated', show, rid, drid, tab, data.uid, mod);

	// TBD: reconcile updating mytaskslegend directly 
	//	vs comp.reload.
	// Let comp.reload handle this than to call mytaskslegend directly
	//	TBD
	if (mod && window.g_myTasks && window.g_myTasks._isMounted)
		window.g_myTasksLegend.got_new();

	if (show && comp._isMounted)
		comp.refresh();
	
	// safe_my_rooms_refresh();
}

function new_chat_mcast(data) {
	const comp = window.g_chat;
	if (comp && comp._isMounted)
		comp.refresh();
	// new flag is not working ... tbd
	// else 
		// window.g_newChatFlag.setState({has_new:true});
}

function tag_mcast(data) {
	const drid = parseInt(data.rid, 10);
	const {rid, tab, comp} = window.g_current_list_view;
	if (comp && comp._isMounted && rid == drid && tab == 'shared')
		comp.refresh();

	set_tag_count( get_num_tags(true) );
	window.g_appMenuTab.refresh();
}

function read_tag_mcast(data) {
	set_tag_count( get_num_tags(true) );
	window.g_appMenuTab.refresh();
}

function email_transferred_mcast(data) {
	const {rid, tab, comp} = window.g_current_list_view;

	const rids = new Set();
	const ueids = new Set();

	data.items.forEach(item => {
		const drid = parseInt(item.rid);
		const room = get_room(drid);
		const unshared = room.unshared;

		rids.add(drid);

		if (unshared === undefined)
			return;

		let rmid = 0;

		foreach_list(unshared, (mce,id) => {
			if (mce.msgid == item.msgid)
				rmid = id;
		});
		list_rm_item(unshared, rmid);

		ueids.add(rmid);

		log('lib', 'email_transferred', rid, drid, 
				item.msgid, rmid, ueids);
	})

	const irid = parseInt(rid, 10);
	let iiid = 0;
	if (comp && comp.props && comp.props.iid)
		iiid = parseInt(comp.props.iid, 10);

	if (!rids.has(irid)) {
		return;
	}
	
	if (ueids.has(iiid))
		comp.redir_iid();
	else
		comp.refresh();
}

function unshared_private_mcast(data) {
	const unshared = get_room(data.rid).unshared;

	if (data.error)
		growl('error', data.error);

	data.eids.forEach(eid => {
		//list_rm_item(unshared, eid);
		const env = get_item(data.rid, 'unshared', eid);
		env._being_moved = false;
		env.is_private = true;
		log('lib', 'unshared_private', data.rid, eid);
	})
	growl('Marked Private');

	const {rid, tab, comp} = window.g_current_list_view;
	if (comp && rid == data.rid && tab == 'unshared')
		comp.refresh();
}

function unshared_nonprivate_mcast(data) {
	const unshared = get_room(data.rid).unshared;

	if (data.error)
		growl('error', data.error);

	data.eids.forEach(eid => {
		//list_rm_item(unshared, eid);
		const env = get_item(data.rid, 'unshared', eid);
		env._being_moved = false;
		env.is_private = false;
		log('lib', 'unshared_nonprivate', data.rid, eid);
	})
	growl('Marked Non-Private');

	const {rid, tab, comp} = window.g_current_list_view;
	if (comp && rid == data.rid && tab == 'unshared')
		comp.refresh();
}

// If tab/item room is showing got removed/updated w data from server,
//	update view. 
function room_refresh(nrid, ntab, niid) {
	const {rid, tab, iid, comp} = window.g_current_list_view;

	log('lib', 'room_refresh', nrid, ntab, niid, rid, tab, iid, comp);

	if (!comp || nrid != rid || ntab != tab)
		return;

	if (iid == niid)	// item got removed. get room to find new item.
		comp.redir_iid();
	else
		comp.refresh()
}

// Env was deleted. 
function rm_deleted_env(rid, env) {
	const room = get_room(rid);
	
	log('lib', 'email_deleted. rm_env', env);

	list_rm_item(room.unshared, env.id);

	room_refresh(rid, 'unshared', env.id)
}

// Env was transferred. 
function rm_env(env) {
	const room = get_room(env.rid);
	
	log('lib', 'email_transferred. rm_env', env);

	list_rm_item(room.unshared, env.id);

	room_refresh(env.rid, 'unshared', env.id)
}

function rm_transferred_env(rid, eid) {
	const room = get_room(rid);
	
	log('lib', 'email_transferred. rm_transferred_env', eid);

	list_rm_item(room.unshared, eid);

	room_refresh(rid, 'unshared', eid)
}

// Env was untransferred. 
function rm_shared_env(env) {
	const room = get_room(env.rid);
	
	log('lib', 'email_untransferred. rm_shared_env', env);

	list_rm_item(room.shared, env.id);

	room_refresh(env.rid, 'shared', env.id)
}

function renamed_room_mcast(data) {
	const {rid, tab, comp} = window.g_current_list_view;
	const drid = parseInt(data.rid);
	const room_name = data.room_name;

	const room = get_room(drid);
	if (!room)
		return;
	
	room.name = room_name;
	
	// window.g_appMenu.refreshMenu();
}

function categorized_room_mcast(data) {
	const {rid, tab, comp} = window.g_current_list_view;
	const drid = parseInt(data.rid);
	const room_category = data.room_category;

	const room = get_room(drid);
	if (!room)
		return;
	
	room.category = room_category;

	safe_my_rooms_refresh();

	// window.g_appMenu.refreshMenu();
}

function deleted_room_mcast(data) {
	const room = get_room(data.rid);
	if (room)
		window.location = '/#/reconnect/room_deleted/' + data.rid ;
}

function deleted_client_mcast(data) {
	const {rid, cid, by} = data;
	const room = get_room(rid);
	if (!room)
		return;

	list_rm_item(room.clients, cid);

	if ((by == ldb.data.me.id) && (window.g_deleteClient))
		window.g_deleteClient.props.par.setState({});

	// window.g_appMenu.refreshMenu();
}

function deleted_client_email_id_mcast(data) {
	const {rid, cid, ceid, by} = data;
	const room = get_room(rid);
	if (!room)
		return;
	
	log('mcast','deleted_client_email_id', data);
	list_rm_item( room.clients._items[cid].emails, ceid );

	// window.g_appMenu.refreshMenu();
}

function finished_deleting_client_mcast(action) {
	const {bid} = action;

	window.g_progCircle.done(bid);
}

function new_email_mcast(action) {
	const {rid, tab, id, bid, error, caption} = action;
	if (error) {
		// growl(caption);
		log('mcast','new_email_mcast', action);
	}
	refresh_tab_if_current(rid, tab);
	
	if (tab == 'unshared') {
		const mbox_room = get_mailbox_room();
		if (mbox_room) {
			refresh_tab_if_current(mbox_room.id, tab);
		}
	}
	
	if (bid) {
		window.g_progCircle.done(bid);	
	}
}

function remove_deleted_email_mcast(action) {
	const {rids, id} = action;
	const tab = 'unshared';
	rids.forEach(rid => {
		const env = get_item(rid, tab, id);
		if (!env)
			return;
		console.log("REMOVE TRANSFERRED EMAIL", env);
		rm_deleted_env(rid, env);
		refresh_tab_if_current(rid, tab);
	});
	growl('Deleted Email',
		'');
}

function remove_multiple_deleted_emails_mcast(action) {
	const {rids, ids} = action;
	const tab = 'unshared';
	ids.forEach(id => {
		rids.forEach(rid => {
			const env = get_item(rid, tab, id);
			if (!env)
				return;
			console.log("REMOVE TRANSFERRED EMAIL", env);
			rm_deleted_env(rid, env);
			refresh_tab_if_current(rid, tab);
		});
	});
	growl('Deleted Emails',
		'');
}

function remove_multiple_deleted_shared_emails_mcast(action) {
	const {rids, ids} = action;
	const tab = 'shared';
	ids.forEach(id => {
		rids.forEach(rid => {
			const env = get_item(rid, tab, id);
			if (!env)
				return;

			rm_shared_env(env);
			refresh_tab_if_current(rid, tab);
		});
	});
	growl('Deleted Emails',
		'');
}

function returned_multiple_emails_to_inbox_mcast(action) {
	const {rids, ids} = action;
	const tab = 'unshared';
	ids.forEach(id => {
		rids.forEach(rid => {
			const env = get_item(rid, tab, id);
			if (!env)
				return;
			rm_deleted_env(rid, env);
			refresh_tab_if_current(rid, tab);
		});
	});
	growl('Returned Emails to Inbox',
		'');
}

function remove_env_from_mailbox_room(eid, refresh_tab=true) {
	const mailbox_room = get_mailbox_room();
	if (!mailbox_room)
		return;
	
	const tab = 'unshared';
	const rid = mailbox_room.id;
	const env = get_item(rid, tab, eid);
	if (!env)
		return;

	rm_deleted_env(rid, env);
	if (refresh_tab)
		refresh_tab_if_current(rid, tab);
}

function remove_transferred_email_mcast(action) {
	const {rid, id, bid, hrids} = action;
	const tab = 'unshared';

	window.g_progCircle.done(bid);
	
	// Handle sharing the email from the main inbox feature in
	// any room, regardless of whether it is a home room of the env
	if (hrids.indexOf(rid) == -1) 
		hrids.push(rid);

	console.log('REMOVE TRANSFERRED EMAIL MCAST', hrids, bid);
	hrids.forEach(hrid => {
		let env = null;
		try {
			env = get_item(hrid, tab, id);
		} catch(error) {
		}
		
		console.log('ENV', hrid, env);
		if (env) {
			rm_transferred_env(hrid, env.id);
			refresh_tab_if_current(hrid, tab);
		}
	});

	try {
		const mailbox_room = get_mailbox_room();
		if (mailbox_room) {
			if (mailbox_room.id != rid) {
				remove_env_from_mailbox_room(id);
			}
		}
	} catch(error) {
	}
}

function remove_undo_transferred_email_mcast(action) {
	const {rid, id, bid} = action;
	const tab = 'shared';
	const env = get_item(rid, tab, id);
	if (env) {
		window.g_progCircle.done(bid);
		rm_shared_env(env);
		refresh_tab_if_current(rid, tab);
	}
}

function updated_my_settings_mcast(action) {
	const me = get_staff(ldb.data.me.id);
	ldb.data.me.settings = JSON.parse(me.settings_json);
}

function activity_flags_cleared_mcast(action) {
	growl('Cleared Notification Flags',
		'All notification flags have been cleared');
}

function email_drafts_cleared_mcast(action) {
	ldb.data.drafts = {};
	
	for (let rid in ldb.data.rooms._items) {
		const room = ldb.data.rooms._items[rid];
		room.shared.drafts = {};
	}
	
	growl('Cleared Email Drafts',
		'All email drafts have been cleared');
}

function transfer_error_mcast(action) {
	const {rid, id, bid, error} = action;
	const tab = 'unshared';
	const env =  get_item(rid, tab, id);

	window.g_progCircle.done(bid, 1, error);
	
	env._being_moved = false;
	env._move_error = env.error;
	refresh_tab_if_current(rid, tab);
}

function comment_cast(action) {
	// For now used to send a comment for action.
	// DB Merge with post fix is enough. No need for additional actions.
	log('mcast', action.comment);
}

function display_msg_mcast(action) {
	growl(action.msg);
}

function org_assigned_room_mcast(action) {
	const {bid} = action;

	window.g_progCircle.done(bid);
}

function org_unassigned_room_mcast(action) {
	const {bid} = action;

	window.g_progCircle.done(bid);
}

function created_room_mcast(action) {
	const {bid, rid, room_name, silent} = action;

	log('lib', 'room ready', bid, rid, room_name);
	if (window.g_createRoom)
		window.g_createRoom.room_ready(bid, rid, room_name);

	update_multicast_channels();

	window.g_progCircle.done(bid);

	if (!silent)
		growl('Room created: ' + room_name);
}

function add_room_mcast(action) {
	const {room_name} = action;
	update_multicast_channels();
	growl('Room ready: ' + room_name);
}

function remove_room_mcast(action) {
	const {rid} = action;
	update_multicast_channels();
	// TBD: Remove room from ldb.
}

function sent_bulk_email_mcast(action) {
	const {bid} = action;
	
	if (window.g_progCircle)
		window.g_progCircle.done(bid);
}

function archive_email_complete_mcast(action) {
	const {bid, complete} = action;
	
	if (window.g_progCircle)
		window.g_progCircle.done(bid);

	if (complete)
		growl('Email archive completed');
}

function imap_monitor_pause_staff_mcast(action) {
	growl('ACCOUNT PAUSED', 'Your mail account authentication failed, and your mail account currently is not being monitored by TagInbox. Your TagInbox account has been paused. Please contact your admin to update your mailbox settings and unpause your account', 'error');
}

function ai_test_mcast(action) {
	const {data} = action;
	
	if (!ldb.data.ai_test_output) {
		ldb.data.ai_test_output = [];
	}
	
	let output_history = ldb.data.ai_test_output;
	output_history.push(data);
	ldb.data.ai_test_output = output_history;
	
	const comp = window.g_aitest;
	if (comp && comp._isMounted) {
		comp.refresh({working: false, history: ldb.data.ai_test_output, msg: ''});
	}
}

function ai_predict_mcast(action) {
	const {data} = action;
	
	const cid = data.cid;
	data.working = false;
	
	const comp = window.g_aiprediction;
	if (comp && comp._isMounted) {
		comp.update_history(cid, data);
	}
}

function ai_draft_assist_mcast(action) {
	const {data} = action;

	if (data.error) {
		growl('AI Assist Error', data.error, 'error');
		return;
	}
	
	const rid = data.rid;
	const eid = data.eid;
	const output = data.output;

	const handle_assist = window.g_ai_assist;
	if (handle_assist) {
		console.log('AI ASSIST COMP', handle_assist);
		handle_assist(rid, eid, output);
		//growl(output);
	}
}

const action_handlers = {
	'task_updated' : task_updated_mcast,
	'email_transferred' : email_transferred_mcast,
	'unshared_private' : unshared_private_mcast,
	'unshared_nonprivate' : unshared_nonprivate_mcast,
	'categorized_room' : categorized_room_mcast,
	'renamed_room' : renamed_room_mcast,
	'deleted_room' : deleted_room_mcast,
	'deleted_client' : deleted_client_mcast,
	'deleted_client_email_id' : deleted_client_email_id_mcast,
	'finished_deleting_client' : finished_deleting_client_mcast,
	'new_email' : new_email_mcast,
	'remove_deleted_email' : remove_deleted_email_mcast,
	'remove_multiple_deleted_emails' : remove_multiple_deleted_emails_mcast,
	'remove_multiple_deleted_shared_emails' : remove_multiple_deleted_shared_emails_mcast,
	'returned_multiple_emails_to_inbox' : returned_multiple_emails_to_inbox_mcast,
	'remove_transferred_email' : remove_transferred_email_mcast,
	'remove_undo_transferred_email' : remove_undo_transferred_email_mcast,
	'updated_my_settings' : updated_my_settings_mcast,
	'activity_flags_cleared' : activity_flags_cleared_mcast,
	'email_drafts_cleared' : email_drafts_cleared_mcast,
	'transfer_error' : transfer_error_mcast,
	'tag' : tag_mcast,
	'read_tag' : read_tag_mcast,
	'chat' : new_chat_mcast,
	'comment' : comment_cast,
	'display' : display_msg_mcast,
	'org_assigned_room' : org_assigned_room_mcast,
	'org_unassigned_room' : org_unassigned_room_mcast,
	'created_room' : created_room_mcast,
	'add_room' : add_room_mcast,
	'remove_room' : remove_room_mcast,
	'sent_bulk_email' : sent_bulk_email_mcast,
	'archive_email_complete' : archive_email_complete_mcast,
	'imap_monitor_pause_staff' : imap_monitor_pause_staff_mcast,
	'ai_test' : ai_test_mcast,
	'ai_predict' : ai_predict_mcast,
	'ai_draft_assist' : ai_draft_assist_mcast,
};

function handle_action(action) {
	if (!action)
		return;

	const handler = action_handlers[action.name];
	if (handler !== undefined) {
		handler(action);

		// safe_my_rooms_refresh();
	}

	let {rid, tab, id} = action;
	const clv = window.g_current_list_view;
	if (window.is_mobile && clv.comp && clv.comp._isMounted) {
		rid = pint(rid);
		if (tab == clv.tab && (!rid || pint(clv.rid) == rid)) {
			clv.comp.refresh();
		}
	}
	log('lib', 'ACTION', action);
}

/*============== Cached Data ===============*/


function is_object(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

function to_arr(x) {
	return x || [];
}

function env_people(env) {
	return [
		...(to_arr(env.from_)), 
		...(to_arr(env.to)), 
		...(to_arr(env.cc)), 
	];
}

function name_kws(mid) {
	const member = get_staff(mid);

	return member.name + ' ' + member.email;
}

// DEFUNCT.. TBD: Remove
function set_min_max_id(rid, kind, id) {
	const root = get_room(rid)[kind];

	if (root._flags.max_id === undefined) {
		root._flags.max_id = 0;
		root._flags.min_id = Number.MAX_VALUE;
	}

	if (id < root._flags.min_id)
		root._flags.min_id = id;
	if (id > root._flags.max_id)
		root._flags.max_id = id;
}

function mark_tab_as_read(room, tab) {
	if (show_new_flag(room, tab)) {
		remove_flag(room, tab);
		server_remove_flag(room, tab);
	}
}

function has_activity(room) {
	if (!room.room_activity) {
		return false;
	}
	
	let has_activity = false;

	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		if (item.show_flag) {
			has_activity = true;
		}
	});

	return has_activity;
}

function get_most_recent_public_activity(room) {
	if (!room.room_activity) {
		return null;
	}
	
	let activity = null;
	let recent_dt_last_write = 0;
	
	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		
		let dt_last_write = null;
		if (Number.isInteger(item.dt_last_write)) {
			dt_last_write = item.dt_last_write;
		} else {
			dt_last_write = Date.parse(item.dt_last_write);
		}
		
		if (dt_last_write > recent_dt_last_write) {
			activity = item;
			recent_dt_last_write = dt_last_write;
		}
	});
	
	return activity;
}

function get_my_most_recent_activity(room) {
	if (!room.room_activity) {
		return null;
	}
	
	let activity = null;
	let recent_dt_last_read = 0;
	
	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		
		let dt_last_read = null;
		if (Number.isInteger(item.dt_last_read)) {
			dt_last_read = item.dt_last_read;
		} else {
			dt_last_read = Date.parse(item.dt_last_read);
		}
		
		// console.log('compare', room.name, dt_last_read, recent_dt_last_read)
		if (dt_last_read > recent_dt_last_read) {
			activity = item;
			recent_dt_last_read = dt_last_read;
		}
	});
	
	return activity;
}

function get_general_activity(tab) {
	if (!ldb.data.general_activity) {
		return null;
	}
	
	let activity = null;
	
	const keys = Object.keys(ldb.data.general_activity._items).reverse();
	keys.forEach(key => {
		if (!activity) {
			const item = ldb.data.general_activity._items[key];
			if (item.tab == tab) {
				activity = item;
			}
		}
	});
	
	return activity;
}

function show_new_general_activity_flag(tab) {
	if ((!ldb) || (!ldb.data.general_activity)) {
		return false;
	}
	
	let show_flag = false;
	
	const activity = get_general_activity(tab);
	//console.log('SHOW NEW GENERAL ACTIVITY FLAG');
	//console.log(activity);
	if ((activity) && (activity.show_flag))
		show_flag = true;
	
	return show_flag;
}

function get_room_activity(room, tab) {
	const field = '_activity_' + tab;
	const cache = room._flags[field];
	if (cache !== undefined)
		return cache;

	for (const [key, item] of Object.entries(room.room_activity._items)) {
		// log('lib', 'activity', room, tab, key, item);
		if (item.tab == tab) {
			room._flags[field] = item;
			return item;
		}
	}
	
	return null;

	
	/*--- old code TBD: remove **
	let activity = null;
	
	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		if (!activity) {
			const item = room.room_activity._items[key];
			if (item.tab == tab) {
				activity = item;
			}
		}
	});
	
	return activity;
	**/
}

function show_new_flag(room, tab) {
	if (!room.room_activity) {
		return false;
	}
	
	let show_flag = false;

	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		if (item.tab == tab) {
			if (item.show_flag === true) {
				show_flag = true;
			}
		}
	});

	return show_flag;
}

// newness
//	0 = not new
//	1 = new, not for me
//	>= 2 = new and for me (action needed)

const g_newness = { no : 0, others: 1, me: 2 };

function is_newer(room_activity) {
	return moment( room_activity.dt_last_write ).isAfter(
		moment( room_activity.dt_last_read )
	);
}

function get_newness( room_activity ) {
	const mysid = ldb.data.me.id;
	let others = 0;

	// In unshared tab, all activities are for me.
	if (room_activity.tab == 'unshared') {
		if (room_activity.news.length)
			return g_newness.me;
		return g_newness.no;
	}

	// See if any of the new_acts are for me.
	for (let i=0; i < room_activity.news.length; i++) {
		const act = room_activity.news[i];

		if (act.by == mysid)
			continue;

		if (list_has_ele( act.to, mysid ))
			return g_newness.me;

		others++;
	}

	if (others == 0)	// All new items are generated by me
		return g_newness.no;

	return g_newness.others;

	// No need to check it here.. return g_newness.others?
	// See if write > read. new for others?
	// if (is_newer( room_activity ))
		// return g_newness.others;

	// Newness reset
	// return g_newness.no;
}

function update_new_flags(ra) {
	// If login is not complete, rooms are not set.
	//	return now and init_new_flags will do the initialization.
	if (!ldb.data.me)		
		return;

	if (ra._flags === undefined)
		ra._flags = {};

	// We mark every item updated after date_zero as "new",
	//	until it is "seen".
	// This way we track new items in each tab, until they're clicked on.
	// This is only good for "current session", because we don't track
	//	_seen on server. Not trivial for shared items.
	// TBD for a later version.
	// 
	ra.date_last_read = Date.parse(ra.dt_last_read || ra.dt_updated);
	if (ra._flags.date_zero === undefined)
		ra._flags.date_zero = ra.date_last_read;

	// log('lib', 'update_new_flags', ra);

	const room = get_room(ra.rid);
	let count = 0, cur = 0, nmax = 0;

	ra.news = JSON.parse(ra.new_json);

	room._flags._tabs[ ra.tab ] = 			// cache it in room
		ra._flags._newness = 			// compute
			cur = 
				get_newness( ra );

	// Update the cumulative value of newness across tabs,
	//	saved in room._flags._newness;

	const prev = room._flags._newness || g_newness.no;

	// Cumulative newness is diff from this tab. set it on or off.
	if (prev != cur) {
		if (!prev) {
			room._flags._newness = cur;
		}
		else {
			// see if any other tabs are new, before resetting.
			nmax = 0;
			for (const [tname, tnew] of 
					Object.entries(room._flags._tabs))
				if (tnew > nmax)
					nmax = tnew;

			room._flags._newness = nmax;
		}
	}
	room.info.__for_me = {value: room._flags._newness == g_newness.me};
	room.info.__new = {value: room._flags._newness == g_newness.others};
}

function init_new_flags() {
	log('lib', 'init_new_flags');

	foreach_list( ldb.data.rooms, room => {
		foreach_list( room.room_activity, update_new_flags )
	});
}

function room_set_new_flags(room) {
	foreach_list( room.room_activity, update_new_flags )
}

// defunct? 
function get_new_flag_color(room, tab) {
	let flag_color = 'new-activity-medium';

	if (!room.room_activity) {
		return flag_color;
	}
	
	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		if (item.tab == tab) {
			if (item.priority > 0) {
				if (item.priority == 40)
					flag_color = 'new-activity-low';
				if (item.priority == 30)
					flag_color = 'new-activity-medium';
				if (item.priority == 20)
					flag_color = 'new-activity-high';
				if (item.priority == 10)
					flag_color = 'new-activity-urgent';
			} else {
				if (item.flag_color == 'y')
					flag_color = 'new-activity-low';
				if (item.flag_color == 'r')
					flag_color = 'new-activity-medium';
			}
		}
	});

	return flag_color;
}

// defunct? 
function remove_general_activity_flag(tab) {
	if (!ldb.data.general_activity) {
		return;
	}
	
	const activity = get_general_activity(tab);
	if (activity)
		activity.show_flag = false;
}

function remove_flag(room, tab) {
	if (!room.room_activity) {
		return;
	}
	
	const keys = Object.keys(room.room_activity._items).reverse();
	keys.forEach(key => {
		const item = room.room_activity._items[key];
		if (item.tab == tab) {
			item.show_flag = false;
			//item.dt_last_read = Date.now(); 
		}
	});
}

function server_remove_flag(room, tab) {
	const cmd = 'update_room_activity';
	let rid = null;
	if (room)
		rid = room.id;
	const args = {cmd, rid, tab};
	api( args );
}

function show_tag_flag(room) {
	if (!ldb.data.tags) {
		return {'pri': 0, 'show_flag': false};
	}
	
	let show_flag = false;
	let highest_pri = 0;

	const keys = Object.keys(ldb.data.tags._items);
	keys.forEach(key => {
		const item = ldb.data.tags._items[key];
		if ((item.rid === room.id) && (item.to_sid === ldb.data.me.id)
			&& (!item.dt_done)) {
			show_flag = true;
			if (item.priority > highest_pri)
				highest_pri = item.priority
		}
	});

	const pri = pri_v2n(highest_pri);

	return {'pri': pri, 'show_flag': show_flag};
}

function get_archive_rooms(par_room) {
	let archive_rooms = [];

	for (let rid in ldb.data.rooms._items) {
		const room = ldb.data.rooms._items[rid];
		if (room.archive_of == par_room.id) {
			archive_rooms.push(room);
		}
	}
	
	return archive_rooms;
}



function update_left_menu() {
	const read_perm = mbox_read_perm();
	const write_perm = mbox_write_perm();
	
	if (!write_perm) {
		//clientWinMenu.splice( clientWinMenu.findIndex(f => f.label === 'Create Room'), 1);
		const index = clientWinMenu.findIndex(f => f.label === 'Create Room');
		if (index > -1) {
			clientWinMenu[index].className = 'hide';
		}
	}
	
	if (!read_perm) {
		//clientWinMenu.splice( clientWinMenu.findIndex(f => f.label === 'My Mailbox'), 1);
		const index = clientWinMenu.findIndex(f => f.label === 'My Mailbox');
		if (index > -1) {
			clientWinMenu[index].className = 'hide';
		}
	}
}



function update_contacts_clients(room, words) {
	// We create a cache to cross ref client email address
	//	to a name and rid. So if a name is missing in incoming email
	//	we can guess it. also can be used in search.
	const clients = ldb.data.contacts.clients;

	// cimr_update
	foreach_dict(room.clients, client => {
		foreach_dict(client.emails, email => {
			clients[email.email] = {name: client.name,
						ceid: email.id,
						cuid: client.id,
						rid: room.id};
			words.push(email.email);
		})
		words.push(client.name);
		if (client.smstel)
			words.push(client.smstel);
	})
}


// envdb = Env DB Record in TagInbox... 
//	not a general purpose envelope
function get_envdb_persons(envdb) {
	const pidbuf = envdb.from_pids + ',' + envdb.to_pids + ',' +
			envdb.cc_pids 
	// exclude bcc. could be dangerous:
	//	replies to bcc'd emails could include bcc'd recipients.
		// + ',' + envdb.bcc_pids  
			;
	const pids = pidbuf.split(',').filter(x => x.length).map(
				x => parseInt(x, 10));
	return pids.map(pid2person).filter(x => x !== undefined);
}

function get_envdb_to_persons(envdb) {
	const pidbuf = envdb.from_pids + ',' + envdb.to_pids; 
	const pids = pidbuf.split(',').filter(x => x.length).map(
				x => parseInt(x, 10));
	return pids.map(pid2person).filter(x => x !== undefined);
}

function get_envdb_cc_persons(envdb) {
	const pidbuf = envdb.cc_pids; 
	const pids = pidbuf.split(',').filter(x => x.length).map(
				x => parseInt(x, 10));
	return pids.map(pid2person).filter(x => x !== undefined);
}

function get_envdb_from_person(envdb) {
	const pidbuf = envdb.from_pids; 
	const pids = pidbuf.split(',').filter(x => x.length).map(
				x => parseInt(x, 10));
	return pids.map(pid2person).filter(x => x !== undefined);
}

function reset_page() {
	return {
		// last_id: < 0 => fetch. 0 = no items, > 0 some items
		last_id : 0, 	

		last_dt : null,	
		has_more: true,	// has more items
		total : -1,	// total number of items, -1 => not fetched
		size: 25,
		_fetching_page: false,	// True during FetchPage
		_fetching_iids: [],	// Item Ids being fetched
		kind: '',	// '' | client_emails | inbox_emails

		search: '',	// for now diff from global _flags._search_text
				// used in unshared list, for now.
	};
}

function drafts_tab(room) {
	return room.is_mailbox ? 'unshared' : 'shared';
}

function is_favorite_room(room) {
	if (!room) {
		return false;
	}
	if (!room.badge) {
		return false;
	}
	return room.badge.favorite;
}

function is_return_later_room(room) {
	if (!room) {
		return false;
	}
	if (!room.badge) {
		return false;
	}
	return room.badge.return_later;
}

function get_room_my_notes(room) {
	if (!room) {
		return null;
	}
	if (!room.badge) {
		return null;
	}
	return room.badge.my_notes;
}

function set_display_linked_images_flag(env, value) {
	if (env._flags === undefined)
		env._flags = {};
	
	env._flags.display_linked_images = value;
}

function display_linked_images_for_env(env) {
	if (ldb.data.org.settings.show_images_by_default)
		return true;
	
	if (env._flags === undefined)
		return false;
	
	const display = env._flags.display_linked_images ? true : false;
	return display;
}

function set_enable_links_flag(env, value) {
	if (env._flags === undefined)
		env._flags = {};
	
	env._flags.enable_links = value;
}

function enable_links_for_env(env) {
	if (ldb.data.org.settings.enable_links_by_default)
		return true;
	
	if (env._flags === undefined)
		return false;
	
	const display = env._flags.enable_links ? true : false;
	return display;
}

function is_ticket_org() {
	if (ldb.data.org.settings.ticket_org)
		return true;
	
	return false;
}

function display_account_type(item, prefix=true) {
	const prefix_txt = prefix ? 'Account Type: ' : '';

	if (item.account_type == 'n') {
		return null;
	}

	if (item.account_type == 'p') {
		return <span>{prefix_txt}Preserve Inbox Mode</span>
	}

	if (item.account_type == 'r') {
		return <span>{prefix_txt}Partial Mailbox Monitoring</span>
	}

	if (item.account_type == 'g') {
		return <span>{prefix_txt}No Mailbox Monitoring</span>
	}
	
	return null;
}

function show_sandbox() {
	if (is_local_server() || is_test_server()) {
		return true;
	}

	if (ldb && ldb.data && ldb.data.org && ldb.data.org.test_org) {
		return true;
	}
	
	return false;
}

// getnext in flags marks how many items we got... and resumes from there.
function init_tab_db(room, tab) {
	room[tab] = init_list( {drafts:{}} );
	room[tab]._flags.page = reset_page();
	if (tab == 'shared')
		room[tab]._flags.is_threaded = true;
	else if (tab == 'unshared')
		room[tab]._flags.page.kind = 'client_emails';
}


function tag_room(tag) {
	const room = get_room(tag.rid);
	if (!room)
		return [];
	
	const tpris = room._flags._tagpris;
	const mysid = ldb.data.sid ; // ldb.data.me.id may not be inited yet

	if (tag.to_sid == 0) {
		// tag is done. remove it from my list.
		delete tpris[ tag.id ];
	}
	else if (tag.to_sid == mysid)
		tpris[ tag.id ] = tag.priority;

	const by = get_staff(tag.by_sid);
	const words = [tag.note, room.name, by.name];

	return words;
}

function wnotify(kind, item) {
	let msg = '';
	const mysid = ldb.data.me.id;

	switch(kind) {
	case 'tag':
		if (item.to_sid == mysid) 
			msg = `${get_staff(item.by_sid).name} tagged you in ${get_room(item.rid).name} room: ${item.note}`;
		break;

	case 'task':
		if (item.to_sid == mysid) 
			msg = `${get_staff(item.by_sid).name} assigned you a task in ${get_room(item.rid).name} room: ${item.name}`;
		break;


	};

	if (!msg)
		return;

	log('lib', 'webnotify', kind, item, msg);
	ldb.data.webnotify.webpushreg.showNotification(msg);
}

function check_for_draft_shell(rid, iid) {
	const shells = ldb.data.draft_shells._items;

	for (const key in shells) {
		const item = shells[key];

		if ((item['rid'] == rid) && (item['iid'] == iid)) {
			return item;
		}
	}
	
	return null;
}

function delete_draft_shell(rid, iid) {
	const shells = ldb.data.draft_shells._items;

	for (const key in shells) {
		const item = shells[key];

		if ((item['rid'] == rid) && (item['iid'] == iid)) {
			delete shells[key];
			return true;
		}
	}
	
	return false
}

/* IMPORTANT: TBD:
 *	deepmerge must process members before rooms.
 *	Otherwise, rooms keywords won't be able to get all the members
 *	Only members has list of rids, 
 *	Sending mids for rooms is not good enough if member data is
 *	not processed yet.
 */

function gen_kw_postfix(kind, item, list, req_or_data, postfns) {
	var words = [];
	let rid = null;
	let room = null;

	// log('lib', 'gen_kw_postfix', kind, item, list, req_or_data);

	switch(kind) {
	case 'room':
		const _rid = item.id;
		extend_list(item.staffs, {_redir: 'staffs', _rid});
		item.info = JSON.parse(item.info_json);
		item.triggers = JSON.parse(item.triggers_json);
		item.history = JSON.parse(item.history_json);

		if (item._flags === undefined)
			item._flags = { 
				_newness: 0, // overall newness for room
				_tabs: {},  // { unshared: 0, shared: 2, .. }
				_tagpris: {}, // {tag.id: tag.priority, ..}
					};

		item.info.__favorite = {value: item.badge.favorite};
		item.info.__return_later = {value: item.badge.return_later};

		init_tab_db(item, 'unshared');
		init_tab_db(item, 'shared');
		init_tab_db(item, 'task');
		init_tab_db(item, 'chat');

		words = [item.name];
		rid = _rid;
		update_contacts_clients(item, words);
		item.cids = new Set();	// connection ids
		
		if (item.category) {
			words.push(item.category);
		}
		
		if (item.is_mailbox) {
			ldb.data.sys.mailbox_rid = item.id;
			clientWinMenu.forEach(menu => {
				if (menu.url == '#/mailbox')
					menu.url = 
				`#/room/${item.id}/unshared/-1`;
			});
			item.unshared._flags.page.kind = 'emails_without_rooms';
		}
		break;

	case 'staff':
		words = [item.name, item.email];
		item.onlines = new Set();
		item.offlines = new Set();
		break;

	case 'person':
		words = [item.name, item.email];
		if (item.kind == 's')
			words.push('staff');
		else if (item.kind == 'c')
			words.push('client');
		break;
	
	case 'connection':
		if (ldb.data.sys.dt_login) {
			// we're not initializing... so in case
			//	this contact was removed from some rooms,
			//	remove from all and then add
			map_list(ldb.data.rooms, 
				room => room.cids.delete(item.id));
		}
		if (item.rids) {
			item.rids.split(',').map(rid => {
				const room = get_room(rid);
				if (room)
					room.cids.add(item.id);
			});
		}
		break;
	
	case 'online' :
		const staff = get_staff( item.sid );

		staff.onlines.add(item.id);
		if (item.dt_ended)
			staff.offlines.add(item.id);

		staff._online = staff.onlines.size > staff.offlines.size;

		if (window.g_staffList)
			window.g_staffList.refresh();

		window.g_onlineRefresh();

		break;

	case 'unsharedemail':
		// UNUSED
		item.env = JSON.parse(item.env_json);

		// For Unshared, we use ID, not UID
		// 	even though it is called UID.
		// set_min_max_id(item.rid, 'unshared', item.id);
		
		words = [item.env.subject];
		
		for (let i=0; i<item.env.bcc; i++) {
			const x = item.env.bcc[i];
			words.push(x);
		}

		for (let i=0; i<item.env.cc; i++) {
			const x = item.env.cc[i];
			words.push(x);
		}

		for (let i=0; i<item.env.from.length; i++) {
			const x = item.env.from[i];
			words.push(x.email);
			words.push(x.name);
		}

		for (let i=0; i<item.env.to.length; i++) {
			const x = item.env.to[i];
			words.push(x.email);
			words.push(x.name);
		}

		break;

	case 'env':
		item.thread_eids = JSON.parse(item.thread_eids_json);
		item.date_updated = Date.parse(item.dt_updated);
		item.date_added = Date.parse(item.dt_added);

		// Normalize dt_sent:
		// dt_sent is ISO format, works for sorting most of the times
		//	but fails if +00:00 is used for timezone offsets.
		// parsing and converting to ISO would put it all in Zulu 
		//	now it can be used for sorting.
		item.dt_sort = moment.utc(item.dt_sent).toISOString();

		if (item.rid ) {
			// Common. TBD.
			const action = req_or_data.action;
			if (action && (action.name != 'remove_transferred_email')) {
				// New Shared Env, 
				//   not a recently transferred unshared env
				// set_min_max_id(item.rid, 'shared', item.id);
				words = [item.subject];

				// TBD: add persons
			}
		}
		else {
			// Unshared
			if (req_or_data.request)
				// req
				rid  = parseInt(req_or_data.request.rid, 10);
			else
				// data from mcast
				rid  = parseInt(req_or_data.rid, 10);

			// set_min_max_id(rid, 'unshared', item.id);
		
			words = [item.subject];

			get_envdb_persons(item).forEach(person => {
				words.push(person.name);
				words.push(person.email);
			});
		}
		break;

	case 'sharedemail':
		// set_min_max_id(item.rid, 'shared', item.id);

		words = [item.env.subject];

		for (let i=0; i<item.env.bcc; i++) {
			const x = item.env.bcc[i];
			words.push(x);
		}

		for (let i=0; i<item.env.cc; i++) {
			const x = item.env.cc[i];
			words.push(x);
		}

		for (let i=0; i<item.env.from.length; i++) {
			const x = item.env.from[i];
			words.push(x.email);
			words.push(x.name);
		}

		for (let i=0; i<item.env.to.length; i++) {
			const x = item.env.to[i];
			words.push(x.email);
			words.push(x.name);
		}

		break;

	case 'activity':
		break;

	case 'roomactivity':
		update_new_flags(item);
		break;
	
	case 'task':
		item.date_updated = Date.parse(item.dt_updated);
		item.date_added = Date.parse(item.dt_added);
		try {
			words = [item.name, get_staff(item.by_sid).name, item.notes];
		} catch(error) {
		}
		break;

	case 'tag':
		const mark_data = JSON.parse(item.data_json);
		item.mark_data = mark_data;
		
		words = tag_room(item);
		break;
	
	case 'checklist':
		if (item.__delete__)
			list_rm_item(ldb.data.checklists, item.id);
		else {
			item.data = JSON.parse(item.data_json);
			log('lib', 'postfix.checklist', item,
					ldb.data.checklists._order);
		}
		postfns.push( window.g_lists_reload );
		break;
	
	case 'badge':
		const badge_data = JSON.parse(item.data_json);
		const room = get_room(item.rid);
		if (room) {
			room.badge = badge_data;
		}
	
	case 'bulkemaillist':
		if (item.__delete__)
			list_rm_item(ldb.data.bulk_email_lists, item.id);
		else {
			item.data =  JSON.parse(item.data_json);
		}
		break;
	};

	if (kind || !item._keywords)
		item._keywords = words.join(' ');

	if (kind && ldb.data.login_time)
		item._when = moment();

	if (kind && ldb.data.webnotify.active && 
				!ldb.data.webnotify.is_in_focus)
		wnotify(kind, item);

	return rid;
}

function cmpasc(x, y) {
	if (x == y)
		return 0;
	if (x < y)
		return -1;
	return 1;
}

function cmpascLowerCase(x, y) {
	x = x.toLowerCase();
	y = y.toLowerCase();
	if (x == y)
		return 0;
	if (x < y)
		return -1;
	return 1;
}

function cmpdec(x, y) {
	if (x == y)
		return 0;
	if (x > y)
		return -1;
	return 1;
}

/* task sort functions */

function sort_room_by_name(r1, r2) {
	return cmpasc(r1.name, r2.name);
}

function sort_room_by_activity(r1, r2) {
	return cmpdec(
		has_activity(r1), 
		has_activity(r2),
	);
}

function sort_room_by_visited(r1, r2) {
	const r1_act = get_my_most_recent_activity(r1);
	let r1_dt = 0;
	if (r1_act) {
		r1_dt = r1_act.dt_last_read;
		if (r1_dt) {
			r1_dt = moment(r1_dt);
		}
	}

	const r2_act = get_my_most_recent_activity(r2);
	let r2_dt = 0;
	if (r2_act) {
		r2_dt = r2_act.dt_last_read;
		if (r2_dt) {
			r2_dt = moment(r2_dt);
		}
	}
	
	return cmpdec(
		r1_dt,
		r2_dt, 
	);
}

function sort_room_by_category(r1, r2) {
	const categories = ldb.data.org.settings.room_categories || [];

	// log('lib', 'room.sort', r1.id, r1.category, r2.id, r2.category);
	
	if (r1.category == r2.category) {
		return cmpasc(r1.name, r2.name);
	}

	if ((r1.category == '') && (r2.category != '')) {
		return 1;
	}

	if ((r2.category == '') && (r1.category != '')) {
		return -1;
	}
	
	const r1_cati = categories.indexOf(r1.category);
	const r2_cati = categories.indexOf(r2.category);

	if ((r1_cati == -1) && (r2_cati == -1)) {
		return cmpasc(r1.category, r2.category);
	}
	if (r1_cati == -1) {
		return 1;
	}
	if (r2_cati == -1) {
		return -1;
	}
	
	return cmpdec(
		r2_cati, 
		r1_cati, 
	);
}

function sort_room_by_updated(r1, r2) {
	const r1_act = get_most_recent_public_activity(r1);
	let r1_dt = 0;
	if (r1_act) {
		r1_dt = r1_act.dt_last_write;
		if (r1_dt) {
			r1_dt = moment(r1_dt);
		}
	}

	const r2_act = get_most_recent_public_activity(r2);
	let r2_dt = 0;
	if (r2_act) {
		r2_dt = r2_act.dt_last_write;
		if (r2_dt) {
			r2_dt = moment(r2_dt);
		}
	}

	return cmpdec(
		r1_dt,
		r2_dt,
	);
}

function sort_room_by_updated_by(r1, r2) {
	const r1_act = get_most_recent_public_activity(r1);
	let r1_staff_name = '';
	if (r1_act) {
		const r1_staff = get_staff(r1_act.by_sid);
		if (r1_staff) {
			r1_staff_name = r1_staff.name;	
		}
	}

	const r2_act = get_most_recent_public_activity(r2);
	let r2_staff_name = '';
	if (r2_act) {
		const r2_staff = get_staff(r2_act.by_sid);
		if (r2_staff) {
			r2_staff_name = r2_staff.name;	
		}
	}

	if ((r1_staff_name == '') && (r2_staff_name != '')) {
		return 1;
	}

	if ((r1_staff_name != '') && (r2_staff_name == '')) {
		return -1;
	}
	
	return cmpasc(
		r1_staff_name,
		r2_staff_name,
	);
}

function sort_email_by_date(e1, e2) {
	return cmpdec(
		e1.date,
		e2.date
	);
}

function sort_draft_shell_by_room(e1, e2) {
	const r1 = get_room(e1.rid);
	const r2 = get_room(e2.rid);
	
	if (!r2) {
		return 1;
	}

	if (!r1) {
		return -1;
	}
	
	return cmpasc(
		r1.name,
		r2.name
	);
}

function sort_checklist_by_position(c1, c2) {
	return cmpdec(
		c1.data.position,
		c2.data.position,
	);
}

/* task sort functions */

function sort_task_by_category(r1, r2) {
	return cmpasc(r1.category, r2.category);
}

/* sort generators */

function sort_generic(cmpfn, prop) {
	// log('ldb', 'resort: sort_generic', prop);
	return (r1, r2) => cmpfn(r1[prop], r2[prop])
}


function sort_by_staff(field) {		// eg. field: to, by
	field += '_sid';

	log('ldb', 'resort: sort_by_staff', field);
	// example: cmpasc( get_staff(task1.to_sid), get_staff(task2.to_sid) );
	return (r1, r2) => cmpasc(get_staff(r1[field]).name, 
					get_staff(r2[field]).name)
}

function sort_by_room(field) {		// eg. field: to, by
	field += '_rid';

	// example: cmpasc( get_staff(task1.to_sid), get_staff(task2.to_sid) );
	return (r1, r2) => cmpasc(get_room_name(r1[field]), 
					get_room_name(r2[field]))
}

/* sort */

function sort_task(t1, t2) {
	const d1 = t1.dt_updated;
	const d2 = t2.dt_updated;

	if (d1 == d2)
		return 0;
	if (d1 > d2)
		return -1;
	return 1;
}

function sort_activity(a1, a2) {
	const d1 = a1.dt_updated;
	const d2 = a2.dt_updated;

	if (d1 == d2)
		return 0;
	if (d1 > d2)
		return -1;
	return 1;
}

function sort_email(e1, e2) {
	const d1 = e1.env.date;
	const d2 = e2.env.date;

	if (d1 == d2)
		return 0;
	if (d1 > d2)
		return -1;
	return 1;
}

function sort_email_thread(e1, e2) {
	const d1 = e1.tskey;
	const d2 = e2.tskey;

	if (d1 == d2)
		return 0;
	if (d1 > d2)
		return -1;
	return 1;
}

function sort_by_name(s1, s2) {
	const n1 = s1.name.toLowerCase();
	const n2 = s2.name.toLowerCase();

	if (n1 == n2)
		return 0;
	if (n1 < n2)
		return -1;
	return 1;
}

function sort_by_count(g1, g2) {
	return g2.count - g1.count;
}

function sort_member(m1, m2) {
	// Group members by negative IDs (temp), vs positive (Staff/Client)
	if (m1.id < 0 && m2.id > 0)
		return 1;
	if (m1.id > 0 && m2.id < 0)
		return -1;

	const n1 = m1.name.toLowerCase();
	const n2 = m2.name.toLowerCase();

	if (n1 == n2)
		return 0;
	if (n1 < n2)
		return -1;
	return 1;
}

/*
 * Compare function only gets IDs. we need Items, to compare.
 * So we use this function generator to send items to compare function
 */
function compare_items(items, fn) {
	return function (id1, id2) {
		return fn(items[id1], items[id2]);
	}
}

function get_thread_ancestor(item, list) {
	let anc = null;
	for (;;) {
		let par = list._items[ item.tpid ];
		if (par === undefined)
			return anc;
		item = anc = par;
	}
}

function normalize_date(dstring) {
	// Comparing date strings alphabetically doesn't work with time zones
	//	eg.	2019-09-06T16:34:22+00:00	t1
	//		2019-09-06T11:39:05-05:00	t2
	// t2 > t1 although alphabetically t2 < t1
	//	So we convert it to Z (Zero Offset Time), Zulu time.
	//	so they can be compared alphabetically
	
	return moment.utc(dstring).format();
}

// mark_threads
//	sets tskey(float) for each item, to be used in sorting by threads.
// Idea:
//	since items are sorted by id, in descending order,
//		place all ancestors of an env above 
//	tskey = env.id of leaf node + negative depth index as fraction.
//		tskey = thread sort key
//	eg. env[100] ancestors = [90, 80, 70]
//		env.70.tskey = 100.3
//		env.80.tskey = 100.2
//		env.90.tskey = 100.1
//		env.100.tskey = 100.0

function mark_threads(list) {
	foreach_list(list, env => {
		if (env.tskey !== undefined)
			return;

		const aeids = env.thread_eids.ancestor_eids;
		const len_aeids = aeids.length;
		const rid = env.rid;

		env.tskey = env.id;
		env.tsindent = len_aeids ? len_aeids + 1 : 0;

		aeids.forEach( (peid, i) => {
			const penv = get_item(rid, 'shared', peid);
			if (!penv)
				return;
			const tskeybuf = env.id + '.' + i;
			penv.tskey = parseFloat(tskeybuf);
			penv.tsindent = len_aeids - i - 1;
			log('lib', 'mark_threads', env.id, peid, i);
		});
	});
}

function oldmark_threads(list) {
	const msgid2id = {};

	// TBD TBD TBD TBD : Temporary until we switch shared to Envs
	return;

	// build up msgid2id and initialize thread id
	foreach_list(list, item => {
		// Strange error case with Venkata...
		// took several hours to debug
		//	Several emails didn't have message IDs.
		//  	Empty messages (transferred in error?)
		if (item.msgid)
			msgid2id[item.msgid] = item.id;
		item.tpid = 0;	// thread parent id;
		item.tskey = normalize_date(item.env.date); // thread sort key
		item.has_thread_child = false;
	});

	// navigate in_reply_to and setup thread parent id
	foreach_list(list, item => {
		let iid = msgid2id[ item.env.in_reply_to ];
		let par = list._items[ iid ];
		if (par === undefined) {
			// try references
			const references = item.env.references || '';
			const refids = references.split(' ');
			for (let i=0; i<refids.length; i++) {
				iid = msgid2id[ refids[i] ];
				par = list._items[ iid ];
				if (par !== undefined)
					break;
			}
		}
		if (par !== undefined) {
			par.has_thread_child = true;
			item.tpid = par.id;
		}
	});

	// get the highest date (latest child's date) for each ancestor
	foreach_list(list, item => {
		const anc = get_thread_ancestor(item, list);
		if (anc !== null) {
			if (item.tskey > anc.tskey)
				anc.tskey = item.tskey;
		}
	});

	// copy ancestor's date to all progeny
	foreach_list(list, item => {
		const anc = get_thread_ancestor(item, list);
		if (anc !== null) 
			item.tskey = anc.tskey;
	});

	// Sort by latest child's date, and then by their own date.
	foreach_list(list, item => 
			item.tskey = item.tskey + ' ' + 
				normalize_date(item.env.date) );

}

function unmark_threads(list) {
	foreach_list(list, item => item.has_thread_child = false);
}

function get_sort_fns(list, kind) {
	let sortby;

	switch(list._kind || kind) {
		case 'room':
			sortby = list._flags.sortby || 'name';
			// log('lib', 'room_sort', sortby);
			return {name: sort_room_by_name,
				act: sort_room_by_activity,
				updated: sort_room_by_updated,
				category: sort_room_by_category,
				by: sort_room_by_updated_by,
				visited: sort_room_by_visited,
				}[sortby];

		case 'activity':
			return sort_activity;

		case 'task':
			sortby = list._flags.sortby || 'dt_added';
			if (sortby == 'to')
				return sort_by_staff(sortby);
			if (sortby == 'by')
				return sort_by_staff(sortby);
			if (sortby == 'room')
				return sort_by_room(sortby);
			else if (['name', 'category'].indexOf(sortby) >= 0)
				return sort_generic(cmpascLowerCase, sortby);
			else if (sortby == 'priority')
				return sort_generic(cmpasc, sortby);
			else if (sortby == 'id')
				return sort_generic(cmpdec, sortby);
			else
				return sort_generic(cmpdec, sortby);
			break;

		case 'tag':
			sortby = list._flags.sortby || 'dt_added';
			return sort_generic(cmpdec, sortby);
			break;

		case 'staff':
			return sort_by_name;

		case 'person':
			return sort_by_name;

		case 'env':
			// For now, sort Envs in descending ID order.
			// TBD: sort by dt_sent

			/*
			if (list._flags.is_threaded) {
				mark_threads(list);
				return sort_email_thread;
			}
			*/
			return sort_generic(cmpdec, 'dt_sort');

		// defunct
		case 'sharedemail':
			if (list._flags.is_threaded) {
				mark_threads(list);
				return sort_email_thread;
			}
			// fall through
		case 'unsharedemail':
			unmark_threads(list);
			return sort_email;
		
		case 'chat':
			return sort_generic(cmpdec, 'dt_added');

		case 'checklist':
			return sort_checklist_by_position;

		case 'draftshell':
			return sort_draft_shell_by_room;

		break;
	}
}

/*
 * resort
 *	resorts the list when sort_by changes
 *	list._order order changes, but items aren't added/removed
 *		set_order should be used for that.
 *
 * TBD: This is called often and can be time consuming...
 *	Optionally use heuristics to skip.. ie length/sort_order hasn't changed
 *	(possible list has changed, but avoid resorting when a different
 *	item is selected)
 */

function resort(list, kind, context_name) {
	// See comments for context in set_order
	// typically context_name is undefined
	const context = context_name ? list[context_name] : list;
	
	if (context._flags._remote_search_order) {
		return;
	}

	// New items added. Sort list again.
	const fn = get_sort_fns(context, kind);
	const reverse = context._flags.reverse || false;
	if (fn !== undefined) {
		const source = list._redir ? ldb.data[list._redir] : list;
		context._order.sort(compare_items(source._items, fn));
		if (reverse)
			context._order.reverse();
		// log('ldb', 'resort', list._kind, list._idlist);
	}
}

window.g_resort = resort;

function key_s2i(sid) {
	if (typeof(sid) == 'number')
		return sid;

	return parseInt(sid, 10);
}

function postmerge(key, source, target, req, postfns) {
	if (key !== '_items')
		return;

	// deepmerge may not have copied _kind before _items.
	// 	so fetch that from source, not target.
	const kind = source._kind;

	extend_list(target);

	for (const sid in source[key]) {
		const id = key_s2i(sid) ; parseInt(sid, 10);
		list_add_item_nodup(target._idlist, id);
		if (!target._skip_post_proc)
			gen_kw_postfix(kind, target[key][id], target, req,
						postfns);
	}

	if (!target._skip_post_proc) {
		set_order(target);
		resort(target, kind);
	}
}

function deepmerge(req, postfns, target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (is_object(target) && is_object(source)) {
		for (const key in source) {
			if (is_object(source[key])) {
				if (!target[key]) 
					Object.assign(target, { [key]: {} });
				deepmerge(req, postfns, target[key], source[key]);

				postmerge(key, source, target, req, postfns);
					
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
		if (target._custom_post_proc)
			target._custom_post_proc(target);
	}
	return deepmerge(req, postfns, target, ...sources);
}

function clone(x) {
	return Object.assign({}, x);
}

window.dmerge = deepmerge;

/*========= Local Storage ==========*/

function local_storage_save_login_info() {
	const sock = window.g_sock;
	
	localStorage.setItem('login_cred', sock.cred);
}

function local_storage_get_login_info() {
	let email = null;
	let password = null;

	const login_cred = localStorage.getItem('login_cred');
	if (login_cred) {
		const cred = JSON.parse(atob(login_cred));
		email = cred.email;
		password = cred.password;
	}
	return {email, password};
}

function local_storage_remove_login_info() {
	localStorage.removeItem('login_cred');
}

//---

function staff_prop() {
	return ldb.data.me ? 'staff_' + ldb.data.me.id : null;
}

function local_storage_save_preferences(prefs) {
	const prop = staff_prop();
	if (prop) {
		const jprefs = JSON.stringify(prefs);
		localStorage.setItem(prop, jprefs);
		// log('lib', 'save preferences', prop, jprefs);
	}
}

function local_storage_get_preferences() {
	let prefs = {};
	const prop = staff_prop();
	if (prop) {
		const jprefs = localStorage.getItem(prop);
		if (jprefs)
			prefs = JSON.parse(jprefs);
		// log('lib', 'get preferences', prop, jprefs);
	}
	return prefs;
}

function local_storage_update_preferences(nprefs) {
	const prefs = local_storage_get_preferences();
	Object.assign(prefs, nprefs);
	local_storage_save_preferences(prefs);
}

/*============== Register Components ===============*/

// regcomp - debug utility
//	Usage: regcomp(this, 'ContactsAll')
//	Sets g_<name> on window as global for debug window usage
//	Sets name on comp in _name...
//		Useful in search's target_comp etc. to find what component
//		one is..
//	Debug tool

function regcomp(comp, name) {
	const gname = 'g_' + name;
	window[gname] = comp;
	comp._name = name;
	log('lib', 'component register', name);
}

/*============== LDB ===============*/

function gen_xid() {
	return Math.random().toString(36).substr(2,9);
}

function init_list(extra) {
	// search_text : any phrase
	// search_vars: { __for_me: {value: true}, 	// def op: '=='
	//		__tags: {op: '>', value: 0}
	const ret = {_idlist:[], _order:[], 
		_flags: {_search_text: '', _search_vars: {}, _new: true},
	};

	if (extra !== undefined)
		Object.assign(ret, extra);

	if (ret._redir === undefined)
		ret._items = {};	//_items only if it is not redirecting

	return ret;
}

function extend_list(source, extra) {
	if (source._idlist !== undefined)
		return;		// already initialized

	// Add everything but _items
	const ret = {_idlist:[], _order:[], 
		_flags: { _search_text: '', _search_vars: {}, _new: true},
	};

	if (extra !== undefined)
		Object.assign(ret, extra);

	Object.assign(source, ret);
}

function escape_reg_exp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function init_webnotify() {
	const swjs = '/pushnotif.js';
	const wn = ldb.data.webnotify;

	window.addEventListener('blur', e => {
		ldb.data.webnotify.is_in_focus = false;
		// log('ldb', 'Focus Out');
	});

	window.addEventListener('focus', e => {
		ldb.data.webnotify.is_in_focus = true;
		// log('ldb', 'Focus In');
	});

	if (!('serviceWorker' in navigator && 'PushManager' in window)) {
		log('user', 'Web Push Notifications are not supported');
		wn.error = 'unsupported';
		return;
	}
		
	navigator.serviceWorker.register(swjs)
	.then(swReg => {
		log('user', 'Service Worker is registered', swReg);
		wn.webpushreg = swReg;

		wn.permission = Notification.permission;
		log('user', 'Web Notification Permission', wn.permission);
		if (wn.permission == 'granted' && !wn.suspended) {
			log('user', 'Web Notifications Active');
			wn.active = true;
		}
	})
	.catch(function(error) {
		log('user', 'Service Worker Error', error);
		wn.error = 'noserviceworker';
	});
}

function storage_available(type) {
	let storage;
	try {
		storage = window[type];
		const x = "__storage_test__";
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
			// Firefox
			e.code === 1014 ||
			// test name field too, because code might not be present
			// everything except Firefox
			e.name === "QuotaExceededError" ||
			// Firefox
			e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
}

function get_local_storage_size() {
	let total = 0;
	let amount = 0;
	
	for (var x in localStorage) {
		// Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
		amount = (localStorage[x].length * 2) / 1024 / 1024;
		if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
			total += amount;
		}
	}

	return total.toFixed(2);
};

function check_local_storage() {
	ldb.data.local_storage_unavailable_alert = false;
	
	if (!ldb.data.org.settings.save_emails_locally) {
		log('user', 'Local Storage Setting is Off');
		return;
	}
	
	const is_local_storage_available = storage_available("localStorage");
	
	log('user', 'Check Local Storage', is_local_storage_available, window.g_dvault);
	
	if (!is_local_storage_available) {
		ldb.data.local_storage_unavailable_alert = true;
		return;
	}
	
	if (window.g_dvault === null) {
		ldb.data.local_storage_unavailable_alert = true;
		return;
	}
	
	window.g_dvault.turn_on();

	//try {
		window.g_dvault.clean();
	//} catch(e) {
	//}
	
	const local_storage_size = get_local_storage_size();
	
	log('user', 'Local Storage Size', local_storage_size);
}

function ignore_known_errors(message) {
	// Known errors we can ignore..
	//	SunEditor: 
	// Uncaught TypeError: this._iframeAutoHeight is not a function
	        // /**
         // * @description Called when there are changes to tags in the wysiwyg region.
         // * @private  core.js
         // */
        // _resourcesStateChange: function () {
            // this._iframeAutoHeight();
            // this._checkPlaceholder();
        // },
	//

	if (message.indexOf('this._iframeAutoHeight') >= 0)
		return true;
	return false;
}

function error_handler(message, source, lineno, colno, error) {
	if (window.last_error.stack == error.stack) {
		log('lib', 'Duplicate. skip. Report Errors==========', 
			message, error.stack);
		return true;
	}
		
	window.onerror = null;	// prevent infinite loop

	const args = {cmd: 'syslog', kind:'error', 
		message, data: {source, lineno, colno, 
			stack: error.stack}};

	window.last_error = error;
	log('lib', 'Report Errors==========', args);

	window.g_syslog_count++;

	// To prevent api causing errors and going into infinite loop
	//	report first dozen errors for now...
	if (window.g_syslog_count < 12)
		api( args );
	
	if (!ignore_known_errors(message))
		growl('Error', message);

	window.onerror = error_handler;

	return false;	// do whatever system does on errors. ie don't ignore.
}

function log_errors() {
	window.g_syslog_count = 0;
	window.last_error = {message: '', stack: ''};

	window.onerror = error_handler;

	return;

	// unused
	window.addEventListener('error', ev => {
		ev.stopPropagation();

		const {message, stack} = ev.error;
		window.last_error = ev;
		log('lib', 'Report Errors==========', message, stack);
		ev.preventDefault();

		const args = {cmd: 'syslog', kind:'error', 
			message, data: {stack}};

		window.g_syslog_count++;

		// To prevent api causing errors and going into infinite loop
		//	report first dozen errors for now...
		if (window.g_syslog_count < 12)
			api( args );

		growl('Error', message);

	});
}

/*
 * set_order(root)
 *	sets list._order to a subset of list._idlist
 *		based on filter criteria (searching, my vs. all etc.)
 *	resort may need to be called in addition, to sort this subset
 *
 * 	Typically, the list is used to display one view.
 *	Sometimes, like Bulk Clients etc. :e
 */

function set_order(root, context_name) {
	// _flags and _order.  Could be from root, or root._sidebar
	//	as described below.
	let context = {};	

	if (root === undefined)
		return [];
	
	if (Object.keys(root).length === 0) {  // root == {}
		// Empty list.. like my_client_emails sometimes
		root._items = {};
		root._idlist = [];
	}

	// Context_name is typically undefined.
	//	It is used in bulk.contacts.staff.add etc.
	//	Where we need two separate subsets of the same root
	//	One for sidebar, one for mainlist.
	//	In that case, context = 'sidebar' means 
	//	sidebar will have its own _order, _flags etc.
	//	but they all share the same _idlist.
	if (context_name) {
		if (root[context_name] === undefined)
			root[context_name] = {};
		context = root[context_name];
	}
	else
		context = root;

	if (context._flags === undefined)
		context._flags = {};
	
	// log('search', 'SEARCH ORDER CHECK');
	if (context._flags._remote_search_order !== undefined) {
		context._order = context._flags._remote_search_order;
		log('search', 'USING REMOTE SEARCH ORDER', context._order);
		return context._flags._remote_search_order;
	}

	// Almost all the time, root._idlist is what's used
	//	For RoomEmails, for example, a subset of _idlist
	//	makes computation easier.. In that case, use fn to gen
	const _idlist = (context._flags._gen_idlist_fn) ?
		context._flags._gen_idlist_fn(root, context) : root._idlist;

	// No Filter Function and No search text. Bypass filtering
	if (!context._flags._search_text && !context._flags._filter_fn) {
		// Ensure _order is a copy of _idlist. Not same list.
		//	Otherwise, resort bombs
		context._order = [..._idlist];
		return context._order;
	}
	
	// Filter by function and then keywords.
	const search_text = escape_reg_exp(context._flags._search_text);
	const re = RegExp(search_text, 'i');

	// TBD: Might be efficient to separate out filter and search phases
	//	That way, when we always start with filtered set and search
	//	as opposed to filtering from a massive list each time.
	context._order = _idlist.filter( id => {
		const item = get_root_item(root, id);
		var res = true;

		if (context._flags._filter_fn)
			res = context._flags._filter_fn(item);
		
		if (res && search_text)
			res = re.test( item._keywords ) 
		// console.log('KEYWORDS', item._keywords, res, root._idlist);
		return res;
	});
	return context._order;
}

class Ldb {
	constructor() {
		this.data = { 
			me : null, 
			sid: null,	// staff id
			xid: gen_xid(),	// connection id (session)for debugging
			org: {settings: {},
				bulk_rooms : {csv: '', rooms: [], def_sids: [],
					fields: {}, state: 'uninit', err: ''},
			_custom_post_proc: (ignore_arg) => {
				log('ldb', '*********Org Custom Post Proc', ignore_arg);
				ldb.data.org.settings = 
					JSON.parse(ldb.data.org.settings_json);
				}},
			staffs: init_list(),
			rooms: init_list( {draft: {clients:[]}} ),
			tasks: init_list(),	// MyTasks, aggr of room.tasks
			tags: init_list(),	
			checklists: init_list(),	
			contacts: {
				persons: init_list({
					_e2p:{}, // email2person cache. TBD
				}),
				connections: init_list(),
				clusters: [], 
				clients: {}, 
				_flags: {}},
			onlines: init_list(),
			folders: init_list(),
			chat: init_list(),
			logs: init_list( {_skip_post_proc : true}),
			org_logs: init_list( {_skip_post_proc : true}),
			recent_drafts: null,
			emails: {},
			result: {},	// used by some API calls.
			webnotify: {
				webpushreg: null, 
				error: '',
				permission: '',// granted/default/blocked
				suspended: false,
				is_in_focus: true,
				active: false,
			},

			// Release version. 
			//	Each time production server is updated,
			//	increment this one.
			sys: {
				version: {number: 205, 	// placeholder
		blurb: 'July 22, 2020. Smart Names etc.'}, // import override
				dt_login: null,
				diag: {unshared_emails: 
					init_list( {_skip_post_proc : true}),
					inbox_uids: {},
				},
				mailbox_rid: 0,
				is_mdy: true,	// mm/dd/yy format,
						// false if in India etc.
			},
			// nsent: 
			//	{'sender1' : { recip1 : m, recip2: n, ..},
			//	'sender2' : ... 
			//	}
		};
		this.data.sys.version = Version[0];	// imported
		// Not proper English, but staffs : list of staff
		//	staff = single record

		// We only really care about tasks
		//	being added to ldb, and being sent through
		//	postfix, AFTER rooms are added.
		this.key_order = 'org,rooms,staffs,tasks,onlines,envs,tags,drafts'.split(',');
		this.set_browser_title();
	}

	post_login = () => {
		log('ldb', 'post_login', window.location.hash);
		this.data.me = get_staff(this.data.sid);
		this.data.me.settings = JSON.parse(ldb.data.me.settings_json);
		// this.data.org.settings = 
			// JSON.parse(this.data.org.settings_json);
		set_mdy();
		init_new_flags();
	}

	post_login2 = () => {
		// set_new_activity_count();
		ridMRU.init();
		this.set_browser_title();

		// dt_login is checked to see if login is complete
		//	to take diff action in postfix.
		this.data.sys.dt_login = moment();

		let prefs = local_storage_get_preferences();
		if (prefs.rooms_search_vars !== undefined)
			ldb.data.rooms._flags._search_vars = 
				prefs.rooms_search_vars;
		
		update_left_menu();
		init_webnotify();
		set_tag_count( get_num_tags(true) );
		
		log_errors();
		
		begin_idle_poll();

		window.g_vault.init();

		check_local_storage();

	}

	key_sort = (a,b) => (
		this.key_order.indexOf(a) - 
		this.key_order.indexOf(b)
	)

	reorder = (dobj) => {
		const ret = {};
		const keys = Object.keys(dobj).sort(this.key_sort);
		// log('ldb', 'reorder.keys', keys);
		keys.forEach( key => ret[key] = dobj[key] );
		// log('ldb', 'reorder.data', ret);
		return ret;
	}

	load = (orig_source, req_or_mcast_data) => {
		// req_or_mcast_data : 
		//	apidone calls this with req
		//	multicast calls this with data
		// postfix routines know what info to get from these
		g_newActivity = 0;
		const source = this.reorder(orig_source);

		// Calling callbacks during postfix has a problem.
		//	Data is not fully merged into LDB yet.
		//	callbacks won't have access to full data.
		//	Hold onto all callbacks, and call them 
		//	after data is merged.
		const postfns = [];	
		deepmerge(req_or_mcast_data, postfns, this.data, source);

		postfns.forEach(fn => (fn && fn()));

		set_new_activity_count();
		// log('ldb', 'load', this.data, source);
	}

	set_browser_title = () => {
		const host = window.location.hostname.toLowerCase();
		let title = 'TagInbox';
		let color = '';
		if (host.indexOf('cwintest')>=0) {
			title = 'CwTest';
			color = 'purple';
		}
		else if (host.indexOf('localhost')>=0) {
			title = 'CwLocal';
			color = 'green';
		}
		if (this.data.me) {
			const company_name_color = ldb.data.me.settings.company_name_color || '';
			
			if (company_name_color) {
				title += ' - ';
				title += this.data.me.email;
			} else {
				title += ' - ';
				title += get_first_name(ldb.data.me.name);
				title += '@';
				title += ldb.data.org.name;
				title += ':' + this.data.me.email;
			}
		}
		else if (color)
			this.set_favicon(color); // set once, before login
			
		// Called twice.. once before login
		//	and after login, append user's email address.
		document.title = title;
	}

	set_favicon = color => {
		// Distinguish cwintest.com and localhost favicons
		//	from production.
		let link = document.querySelector("link[rel*='icon']") ||
				document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = '/assets/layout/images/' + color + '-favicon.ico';
		document.getElementsByTagName('head')[0].appendChild(link);
	}

	is_logged_in = () => (ldb.data.sys.dt_login != null)
}

var ldb = new Ldb();
window.dldb = ldb;



/*============== Export ===============*/

export {gen_id, get_first_name, init_list, get_root_item, pint,
	enames, edate, edate2, edate3, edate4, edate5, edate6, get_next_prev_eids,
	get_next_prev_ids, fulldate, fulldatetime, fulldatetimehuman, durhuman,
	blogdate, gen_xid, drafts_tab, em, g_newness, clear_dict,
 	set_current_list_view, gen_email_summary, clean_email,
	pid2person, set_cluster_search_buf, has_attachments, 
	is_inline_attachment, email2person, deepcopy, 
	ebody, get_room, get_list, get_item, get_email, get_staff, set_order, 
	Base64, get_tag, env_tags, get_connection, url2room, url2rid,
	url2iid, get_tag_by_me,
	resort, ridMRU, get_body_part, set_new_activity_count, idleTracker,
	get_body_parts_all, sort_room_by_name,
	list_add_item_nodup, list_rm_ele, list_rm_item, list_next_id,
	list_has_ele, ispin, init_tab_db, list_toggle_ele, icontains,
	env_people, isNew, priorities, pri_n2v, pri_v2n, unset_priority,
	settings_url, p2br, validate_email, show_is_new, svg_icon, trimsplit,
	init_new_task, task_category_options, task_category_default,
	room_category_options, room_category_default, validate_tel,
	task_due_date_delta_default, get_task, get_task_by_me,
	select_employees, growl, is_in_viewport,
	text2html, go, go_url, rel_url, go_replace,
	is_local_server, is_test_server, abs_url, myatob,
	compare_items, sort_room_by_activity, sort_email_by_date,
	icc_listen, icc_send, icc_cancel, get_first_id,
	map_list, foreach_list, foreach_dict, 
	cap, filter_list, set_next_fetch_time, reset_page,
	log, ldb, api, sock, get_current_list_view_rid, regcomp,
	get_current_list_view_tab, get_room_staff_by_name_fragment,
	get_current_list_view_comp, split_name, gen_room_name,
	local_storage_save_login_info, 
	local_storage_get_login_info,
	local_storage_remove_login_info,
	local_storage_update_preferences,
	is_within_one_day, has_passed, go_to_settings_url,
	save_growl_notification, get_my_feedback, get_reconnect_url,
	show_new_flag, show_tag_flag, remove_flag, server_remove_flag,
	get_room_activity, mark_tab_as_read,
	get_most_recent_public_activity,
	get_file_icon, display_readable_bytes,
	get_new_flag_color, get_envdb_persons,
	get_envdb_to_persons, get_envdb_cc_persons,
	get_envdb_from_person, get_my_most_recent_activity,
	show_new_general_activity_flag, remove_general_activity_flag,
	get_previous_versions, get_all_versions, get_room_options,
	is_staff_a_recipient_of_shared_email,
	get_mailbox_room, get_client_options,
	is_favorite_room, is_return_later_room, get_room_my_notes,
	display_linked_images_for_env, set_display_linked_images_flag,
	enable_links_for_env, set_enable_links_flag,
	escape_reg_exp, nofun, durhuman_with_title,
	get_signature_of_room, room_signature_options,
	mbox_read_perm, mbox_write_perm, mbox_move_perm,
	display_account_type, is_tag_read, is_my_tag_read, room_set_new_flags,
	init_new_flags, get_num_tags, env_has_my_unread_tags,
	get_current_room, copy_to_clipboard, get_archive_rooms,
	get_prev_room, get_next_room, is_object, html2plain,
	bulk_email_list_options, get_bulk_email_list,
	get_bulk_email_list_data, get_my_mailbox_room,
	room_has_team_email, get_room_team_email,
	any_room_has_team_email, get_room_team_email_display_name,
	get_values_for_room_field, is_super_admin,
	is_ticket_org, show_sandbox, time_diff,
	org_admins_display, check_for_draft_shell, delete_draft_shell,
	get_default_editor_style_settings, is_room_a_cimr_room,
	get_all_client_rooms, get_all_rooms_with_client_email,
	get_from_person_from_env, get_other_rooms_from_rids_csv,
	dv_default_email_recipients, sort_room_by_category,
	static_img_url
};

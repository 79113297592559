import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import {Toolbar} from 'primereact/toolbar';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Dropdown} from 'primereact/dropdown';

import {EditSig} from './EditSig';
import {SanitizeHTML, HelpInfo, ModalCommand, stretchHeight, Focusable} from './Utils';
import {MyTaskDetail} from './MyTasks';
import BulkCreateRooms from './BulkCreateRooms';
import BulkEmailLists from './BulkEmailLists';
import BulkArchive from './BulkArchive';

import {ldb, log, settings_url, go, p2br, api, growl, go_url,
	get_first_name, edate, get_room, gen_xid, em, trimsplit,
	foreach_list, map_list, get_staff, list_rm_ele, list_has_ele,
	is_favorite_room, is_return_later_room, task_due_date_delta_default,
	sort_room_by_name, room_category_options, is_room_a_cimr_room,
	sort_room_by_category} from './Lib';

//------------------ Task Categories  ------------------------

class TaskCategories extends Component {
	constructor() {
		super();
		const categories = ldb.data.org.settings.task_categories || [];
		const tcats = categories.join(' : ');
		this.state = { tcats, updating:false, };
	}
	
	
	update = () => {
		const task_categories = this.state.tcats.split(':').map(
				cat => cat.trim() );
		const new_settings = {task_categories};
		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true});

	}

	updated = (error) => {
		this.setState({updating:false});
	}


	render() {
		const is_org_admin = ldb.data.me.is_org_admin;
		
		const footer = is_org_admin ? <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span> : '';

		const subTitle = 'Category names separated by colon (:)';

		const is_text_input_disabled = is_org_admin ? false : true;

		const explain_text = is_org_admin ? '' : 'Please contact your organization administrator if you need to update this field';

		return (
	<Card title="Task Categories" subTitle={subTitle} footer={footer}>
		<InputTextarea 
			style={{height:'8em', width:'100%',}}
			autoResize={true}
			disabled={is_text_input_disabled}
			value={this.state.tcats} 
			onChange={ e => this.setState({tcats: 
						e.target.value }) }
			/>
		{explain_text}
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

class TaskDueDate extends Component {
	constructor() {
		super();
		const due_delta = task_due_date_delta_default();
		this.state = { due_delta, updating:false, };
	}
	
	
	update = () => {
		let due_delta = parseInt(this.state.due_delta, 10);
		if (due_delta != 0 && !due_delta) {
			growl("Invalid number", '', 'error');
			due_delta = task_due_date_delta_default();
			this.setState({ due_delta });
			return;
		}
			
		const new_settings = {task_due_date_delta: due_delta};
		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true, due_delta});

	}

	updated = (error) => {
		this.setState({updating:false});
	}


	render() {
		const is_org_admin = ldb.data.me.is_org_admin;
		
		const is_text_input_disabled = is_org_admin ? false : true;

		const explain_text = is_org_admin ? '' : 'Please contact your organization administrator if you need to update this field';

		const footer = is_org_admin ? <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span> : '';

		const subTitle = 'Number of days from today'

		return (
	<Card title="Default Due Date" subTitle={subTitle} footer={footer}>
		<InputText
			style={{height:'4em', width:'100%',}}
			value={this.state.due_delta} 
			disabled={is_text_input_disabled}
			onChange={ e => this.setState({due_delta: 
						e.target.value }) }
			/>
		{explain_text}
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ SMS Presets  ------------------------

class SMSPresets extends Component {
	constructor() {
		super();
		const presets = ldb.data.me.settings.sms_presets || [];
		const sms_presets = presets.join(' : ');
		this.state = { sms_presets, updating:false, };
	}
		
	update = () => {
		const sms_presets = this.state.sms_presets.split(':').map(
				cat => cat.trim() );
		const new_settings = {sms_presets};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		const subTitle = 'Presets separated by colon (:)';
		
		return (
	<Card title="SMS Presets" subTitle={subTitle} footer={footer}>
		<InputTextarea 
			style={{height:'8em', width:'100%',}}
			autoResize={true}
			value={this.state.sms_presets} 
			onChange={ e => this.setState({sms_presets: 
						e.target.value }) }
			/>
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ Tag Presets  ------------------------

class TagPresets extends Component {
	constructor() {
		super();
		const presets = ldb.data.me.settings.tag_presets || [];
		const tag_presets = presets.join(' : ');
		this.state = { tag_presets, updating:false, };
	}
		
	update = () => {
		const tag_presets = this.state.tag_presets.split(':').map(
				cat => cat.trim() );
		const new_settings = {tag_presets};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true});

	}

	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		const subTitle = 'Presets separated by colon (:)';
		
		return (
	<Card title="Tag Presets" subTitle={subTitle} footer={footer}>
		<InputTextarea 
			style={{height:'8em', width:'100%',}}
			autoResize={true}
			value={this.state.tag_presets} 
			onChange={ e => this.setState({tag_presets: 
						e.target.value }) }
			/>
	{this.state.creati && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ IP Whitelist  ------------------------

class IpWhitelist extends Component {
	constructor() {
		super();
		const ip_whitelist_data = ldb.data.org.settings.ip_whitelist || [];
		const ip_whitelist = ip_whitelist_data.join(' : ');
		const ip_whitelist_on = ldb.data.org.settings.ip_whitelist_on || false;
		this.state = { ip_whitelist, ip_whitelist_on, updating:false, };
	}
		
	update = () => {
		const ip_whitelist_on = this.state.ip_whitelist_on;
		const ip_whitelist = this.state.ip_whitelist.split(':').map(
				cat => cat.trim() );
		const new_settings = {ip_whitelist_on, ip_whitelist};
		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}
	
	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		const subTitle = 'IP Addresses separated by colon (:)';
		
		return (
	<Card title="IP Whitelist" subTitle={subTitle} footer={footer}>
		<div style={{margin:'5px 0px'}}>
		Use whitelist:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const ip_whitelist_on = e.checked;
				this.setState({ip_whitelist_on});
			}}
			checked={this.state.ip_whitelist_on}
		/>
		<div style={{fontSize:'x-small'}}>
		When on, this feature will prevent logins from staff members from IP addresses not included in the whitelist below. Updating the checkbox value will not take effect until you click the "Update" button below.
		</div>
		</div>
		<InputTextarea 
			style={{height:'8em', width:'100%',}}
			autoResize={true}
			value={this.state.ip_whitelist}
			disabled={!this.state.ip_whitelist_on}
			onChange={ e => this.setState({ip_whitelist: 
						e.target.value }) }
			/>
	{this.state.updating && <ProgressSpinner/>}
	</Card>);
	}
}

//------------------ Clear Org Email Drafts  ------------------------

class ClearOrgEmailDrafts extends Component {
	constructor() {
		super();
		
		this.state = {updating:false};
	}
		
	update = () => {
		const args = {
			cmd: 'org_clear_drafts', 
		}
		api( args, this.updated );

		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}

	render() {
		const footer = <span><Button label="Clear Org Email Drafts" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		return (
	<Card title="Clear Org Email Drafts">
	<span style={{fontSize:'small'}}>This will clear all in-progress email drafts that your org been working on.<br/><br/>This will not affect any emails that have been received or sent.</span>
	<br/><br/>
	{this.state.updating && <ProgressSpinner/>}
	{(!this.state.updating) && footer}
	</Card> );
	}
}

//------------------ Org Settings  ------------------------

const compliance_archive_help = () => (
	<HelpInfo header="About Compliance Archiving" className="normal">
	<div>
		<b>What is the compliance archival feature?</b>
		
		<p>Compliance requirements for financial service companies require that all communication data be stored and retrieved on-demand in a tamper-proof manner, in case of an audit. Most features of TagInbox work through email, which is already covered by common third-party services financial companies subscribe to that archive snapshots of email in write-once storage systems. However, certain features of TagInbox like tasks, tags, and chat do not directly run through email and will not be archived by these services. In order to make sure that your use of TagInbox will meet standard compliance requirements, we provide a compliance archival feature that records task, tag, and chat actions into summaries contained in emails sent to your shared email account, so that they can be recorded in these archival snapshots.</p>
		
		<b>Is the compliance archival feature on for my organization? How do I enable it?</b>
		
		<p>The compliance archival feature is OFF by default. To turn it on for your organization, an organization administrator needs to go to the "Organization" page under "My Org" in the left hand menu. Then go to the "Org Settings" section, check the box for "Compliance Archives", and click "Update". Compliance archiving will begin that night, with the first archiving run covering all tasks/tags/chat created before you turned on compliance archiving.</p>
		
		<b>How often does archiving take place?</b>
		
		<p>Archiving takes place every night (around midnight PST), and covers tasks/tags/chat created or updated in the last 24 hours (unless archiving was just turned on that day, in which case it will archive all task/tags/chat created before the archiving was turned on).</p>
		
		<b>How are tags/tasks/chat archived?</b>
		
		<p>Tags, tasks, and chat are archived by being summarized in emails that are stored in an archive subfolder of your organization's shared email account.</p>
		
		<b>How do I make sure all taginbox data is archived?</b>
		
		<p>When you turn on the compliance archiving feature, task/tag/chat data created by your staff will be archived in the archive subfolder of the shared email account for your organization. All you then need to do is to make sure whatever email archival service you use is set to take archival snapshots of your shared email account, and specifically the archive subfolder.</p>
		
		<b>How do I look through tag/task/chat archives?</b>
		
		<p>You can login to the shared email account for your organization and go to the "taginbox/archive" subfolder. The archive emails are there with subject lines detailing what room (and what tag/task) the email summarizes. The subject line contains the name of the room, the name of the tag/task, who assigned and received the tag/task, and the date range since the chat/tag/task began until the last time it was updated. The email content includes detailed information about the tag/task, or in the case of chat it contains a log of the chat itself.</p>
		<p>You can do searches in the archive folder by a staff member's name, a task subject, a specific date, or more to find the archive emails you need on demand. For example, you can search for "John Smith" to find all tasks and tags assigned to or by John Smith.</p>
		
		<b>What are some examples of subject lines of archive emails?</b>
		
		<p>
		Sample task archive email subject:
		<br/>
		<i>Task Archive for Room "Joe's CPA Firm": Titled "Complete the required forms": Assigned to "John Smith" by "Anna Larson" (2/1/2021-2/14/2021): a-3-231-task-10293</i>
		<br/><br/>
		Sample tag archive email subject:
		<br/>
		<i>Tag Archive for Room "Joe's CPA Firm": Titled "Follow up with this client": Assigned to "Anna Larson" by "Joe Smith" (2/5/2021-2/7/2021): a-3-231-tag-2065</i>
		<br/><br/>
		Sample chat archive email subject:
		<br/>
		<i>Chat Archive for Room "Joe's CPA Firm": Involving "Anna Larson", "Joe Smith" (2/6/2021-2/10/2021): a-3-231-chat</i>
		</p>
		
		<b>How do I know that the archiving is taking place?</b>
		
		<p>Once you turn on archiving, organization admins will receive an nightly email summarizing how many tasks/tags/chats were archived that night. This lets you know that archiving is taking place each evening. Errors with the compliance archiving are recorded in the system logs.</p>
	</div>
	</HelpInfo>
)

class OrgSettings extends Component {
	constructor() {
		super();
		const allow_shared_env_delete = ldb.data.org.settings.allow_shared_env_delete || false;
		const allow_sending_private_emails = ldb.data.org.settings.allow_sending_private_emails || false;
		const enable_links_by_default = ldb.data.org.settings.enable_links_by_default || false;
		const show_images_by_default = ldb.data.org.settings.show_images_by_default || false;
		let newly_created_rooms_are_auto_move = ldb.data.org.settings.newly_created_rooms_are_auto_move;
		if (newly_created_rooms_are_auto_move === null) {
			newly_created_rooms_are_auto_move = true;
		}
		const compliance_archive = ldb.data.org.settings.compliance_archive || false;
		const sms_alert_start_time = ldb.data.org.settings.sms_alert_start_time || 8;
		const sms_alert_end_time = ldb.data.org.settings.sms_alert_end_time || 18;
		const tag_shell_staff_on_client_reply = ldb.data.org.settings.tag_shell_staff_on_client_reply || false;
		const ticket_org = ldb.data.org.settings.ticket_org || false;
		const save_emails_locally = ldb.data.org.settings.save_emails_locally || false;
		const auto_share_with_multiple_home_rooms = ldb.data.org.settings.auto_share_with_multiple_home_rooms || false;
		const email_deletion_in_preserve_inbox_mode = ldb.data.org.settings.email_deletion_in_preserve_inbox_mode || false;
		const sms_disclosure_on = ldb.data.org.settings.sms_disclosure_on || false;
		const sms_disclosure_msg = ldb.data.org.settings.sms_disclosure_msg || '';
		const use_email_sender_notification_exceptions = ldb.data.org.settings.use_email_sender_notification_exceptions || false;
		const email_sender_notification_exceptions = ldb.data.org.settings.email_sender_notification_exceptions || '';
		const email_notification_exception_trigger = ldb.data.org.settings.email_notification_exception_trigger || '';
		
		const start_time_options = [{label:'12am', value:0},{label:'1am', value:1},{label:'2am', value:2},{label:'3am', value:3},{label:'4am', value:4},{label:'5am', value:5},{label:'6am', value:6},{label:'7am', value:7},{label:'8am', value:8},{label:'9am', value:9},{label:'10am', value:10},{label:'11am', value:11},{label:'12pm', value:12},{label:'1pm', value:13},{label:'2pm', value:14},{label:'3pm', value:15},{label:'4pm', value:16},{label:'5pm', value:17},{label:'6pm', value:18},{label:'7pm', value:19},{label:'8pm', value:20},{label:'9pm', value:21},{label:'10pm', value:22},{label:'11pm', value:23}];
		const end_time_options = [{label:'1am', value:1},{label:'2am', value:2},{label:'3am', value:3},{label:'4am', value:4},{label:'5am', value:5},{label:'6am', value:6},{label:'7am', value:7},{label:'8am', value:8},{label:'9am', value:9},{label:'10am', value:10},{label:'11am', value:11},{label:'12pm', value:12},{label:'1pm', value:13},{label:'2pm', value:14},{label:'3pm', value:15},{label:'4pm', value:16},{label:'5pm', value:17},{label:'6pm', value:18},{label:'7pm', value:19},{label:'8pm', value:20},{label:'9pm', value:21},{label:'10pm', value:22},{label:'11pm', value:23},{label:'12am', value:24}];
		
		this.state = { allow_shared_env_delete, allow_sending_private_emails, enable_links_by_default, show_images_by_default, newly_created_rooms_are_auto_move, compliance_archive, sms_alert_start_time, sms_alert_end_time, start_time_options, end_time_options, tag_shell_staff_on_client_reply, ticket_org, save_emails_locally, auto_share_with_multiple_home_rooms, email_deletion_in_preserve_inbox_mode, sms_disclosure_on, sms_disclosure_msg, use_email_sender_notification_exceptions, email_sender_notification_exceptions, email_notification_exception_trigger, updating:false, };
	}
	
	error_check = () => {
		if (this.state.sms_alert_start_time >= this.state.sms_alert_end_time) {
			growl('Error', 'Please select an SMS alert end time that is later than the start time', 'error');
			return true
		}
		
		return false;
	}

	update = () => {
		if (this.error_check()) {
			return;
		}

		const allow_shared_env_delete = this.state.allow_shared_env_delete;
		const allow_sending_private_emails = this.state.allow_sending_private_emails;
		const enable_links_by_default = this.state.enable_links_by_default;
		const show_images_by_default = this.state.show_images_by_default;
		const newly_created_rooms_are_auto_move = this.state.newly_created_rooms_are_auto_move;
		const compliance_archive = this.state.compliance_archive;
		const sms_alert_start_time = this.state.sms_alert_start_time;
		const sms_alert_end_time = this.state.sms_alert_end_time;
		const tag_shell_staff_on_client_reply = this.state.tag_shell_staff_on_client_reply;
		const ticket_org = this.state.ticket_org;
		const save_emails_locally = this.state.save_emails_locally;
		const auto_share_with_multiple_home_rooms = this.state.auto_share_with_multiple_home_rooms;
		const email_deletion_in_preserve_inbox_mode = this.state.email_deletion_in_preserve_inbox_mode;
		const sms_disclosure_on = this.state.sms_disclosure_on;
		const sms_disclosure_msg = this.state.sms_disclosure_msg;
		const use_email_sender_notification_exceptions = this.state.use_email_sender_notification_exceptions;
		const email_sender_notification_exceptions = this.state.email_sender_notification_exceptions;
		const email_notification_exception_trigger = this.state.email_notification_exception_trigger;
		
		const new_settings = {allow_shared_env_delete, allow_sending_private_emails, enable_links_by_default, show_images_by_default, newly_created_rooms_are_auto_move, compliance_archive, sms_alert_start_time, sms_alert_end_time, tag_shell_staff_on_client_reply, ticket_org, save_emails_locally, auto_share_with_multiple_home_rooms, email_deletion_in_preserve_inbox_mode, sms_disclosure_on, sms_disclosure_msg, use_email_sender_notification_exceptions, email_sender_notification_exceptions, email_notification_exception_trigger};
		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}
	
	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		return (
	<Card title="Org Settings" footer={footer}>
		<div style={{margin:'5px 0px'}}>
		Allow Shared Email Deletion:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const allow_shared_env_delete = e.checked;
				this.setState({allow_shared_env_delete});
			}}
			checked={this.state.allow_shared_env_delete}
		/>
		<div style={{fontSize:'x-small'}}>
		When on, this feature will allow staff to delete shared emails in rooms.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Allow Sending Private Emails:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const allow_sending_private_emails = e.checked;
				this.setState({allow_sending_private_emails});
			}}
			checked={this.state.allow_sending_private_emails}
		/>
		<div style={{fontSize:'x-small'}}>
		Normally emails sent from a room are visible to all staff in the room. This feature enables staff to have private conversations with the clients from the room without visibility to other staff in the room. As an example, this lets senior staff members negotiate financial details with the client without looping everyone else in.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Enable Links in Emails by Default:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const enable_links_by_default = e.checked;
				this.setState({enable_links_by_default});
			}}
			checked={this.state.enable_links_by_default}
		/>
		<div style={{fontSize:'x-small'}}>
		When on, links in emails being viewed by staff will be displayed without having to select "Enable Links" for the email.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Show Images in Emails by Default:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const show_images_by_default = e.checked;
				this.setState({show_images_by_default});
			}}
			checked={this.state.show_images_by_default}
		/>
		<div style={{fontSize:'x-small'}}>
		When on, images in emails being viewed by staff will be displayed without having to select "Show Images" for the email.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Newly Created Rooms Are Set to Auto Share By Default:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const newly_created_rooms_are_auto_move = e.checked;
				this.setState({newly_created_rooms_are_auto_move});
			}}
			checked={this.state.newly_created_rooms_are_auto_move}
		/>
		<div style={{fontSize:'x-small'}}>
		When on, the option to have a new room be set to auto share emails will be set to on by default during the room creation process.
		</div>
		</div>
				
		<div style={{margin:'15px 0px'}}>
		Compliance Archive for Tasks, Tags, and Chat:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const compliance_archive = e.checked;
				this.setState({compliance_archive});
			}}
			checked={this.state.compliance_archive}
		/>
		{em(0.5)}
		{compliance_archive_help()}
		<div style={{fontSize:'x-small'}}>
		When on, a daily check will be run to archive a copy of information on tasks, tags, and chat for all rooms for the organization.
		<br/><br/>
		This information will be stored in a subfolder in the shared email account for the organization. This will not affect the tasks, tags, or chat within TagInbox at all.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Tag Shell Accounts on Client Reply:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const tag_shell_staff_on_client_reply = e.checked;
				this.setState({tag_shell_staff_on_client_reply});
			}}
			checked={this.state.tag_shell_staff_on_client_reply}
		/>
		<div style={{fontSize:'x-small'}}>
		When a staff in shell account mode sends an email to a client via a team email account, automatically tag the staff on the email from the client when they reply. 
		</div>
		</div>
		
		<div style={{margin:'15px 0px'}}>
		Ticket Organization:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const ticket_org = e.checked;
				this.setState({ticket_org});
			}}
			checked={this.state.ticket_org}
		/>
		<div style={{fontSize:'x-small'}}>
		If checked, this organization will have the ability to create "ticket rooms" from client emails. Staff can be assigned at the time of creation, and then the ticket room can be closed out when complete. All staff will be removed from the room at that point.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Save In-Progress Emails Locally for Recovery:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const save_emails_locally = e.checked;
				this.setState({save_emails_locally});
			}}
			checked={this.state.save_emails_locally}
		/>
		<div style={{fontSize:'x-small'}}>
		If checked, staff for this organization will have their emails saved locally on the browser while they are being composed. That way if the connection to the server is lost their email will be recoverable.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Auto Share Emails When More Than One Room Applies:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const auto_share_with_multiple_home_rooms = e.checked;
				this.setState({auto_share_with_multiple_home_rooms});
			}}
			checked={this.state.auto_share_with_multiple_home_rooms}
		/>
		<div style={{fontSize:'x-small'}}>
		By default if an email would appear in the unshared emails of multiple rooms, and one of those rooms is set to auto-share, that email is not auto-shared to the room. Turning this setting on will make it so that email is auto-shared instead.
		<br/><br/>
		If the email would appear in the unshared emails of multiple rooms that have auto-share on, then the email will still not be auto-shared to either room, regardless of this setting. 
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Allow Unshared Email Deletion for Staff in Preserve Inbox Mode:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const email_deletion_in_preserve_inbox_mode = e.checked;
				this.setState({email_deletion_in_preserve_inbox_mode});
			}}
			checked={this.state.email_deletion_in_preserve_inbox_mode}
		/>
		<div style={{fontSize:'x-small'}}>
		By default staff in preserve inbox mode are not able to delete unshared emails in rooms or my mailbox. Turning this setting on allows them to delete these emails. All other aspects of preserve inbox mode are maintained.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		SMS Alert Time Range:
		
		{em(0.5)}
		From
		{em(0.5)}
		<Dropdown value={this.state.sms_alert_start_time}
				className="toolbar-dropdown"
	    			options={this.state.start_time_options}
				onChange={e => this.setState({sms_alert_start_time: e.value})}
			/>
		{em(0.5)}
		Till
		{em(0.5)}
		<Dropdown value={this.state.sms_alert_end_time}
				className="toolbar-dropdown"
	    			options={this.state.end_time_options}
				onChange={e => this.setState({sms_alert_end_time: e.value})}
			/>
		
		<div style={{fontSize:'x-small'}}>
		This determines the time range during the day when automated SMS alerts for certain actions (e.g., new emails from a client in rooms set to receive alerts) will be sent out to staff. 
		<br/><br/>
		The timezone for each staff member will be taken into account when determining if the current time falls within this time range.
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Include SMS Disclosure Message:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const sms_disclosure_on = e.checked;
				this.setState({sms_disclosure_on});
			}}
			checked={this.state.sms_disclosure_on}
		/>
		<div style={{fontSize:'x-small'}}>
		Checking this option allows you to define a message that is included at the end of all SMS messages sent by staff of your organization through TagInbox.
		</div>
		</div>
		
		<div style={{margin:'15px 0px'}}>
		SMS Disclosure Message:
		<div style={{marginTop:'5px'}}>
		<InputTextarea 
			style={{height:'2em', width:'100%',}}
			autoResize={true}
			value={this.state.sms_disclosure_msg} 
			onChange={e => this.setState({sms_disclosure_msg: e.target.value})}
			/>
		</div>
		</div>
		
		<div style={{margin:'15px 0px'}}>
		Use Email Sender Notification Exceptions:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const use_email_sender_notification_exceptions = e.checked;
				this.setState({use_email_sender_notification_exceptions});
			}}
			checked={this.state.use_email_sender_notification_exceptions}
		/>
		<div style={{fontSize:'x-small'}}>
		Checking this option allows you to define a comma separated list of email addresses below. When your organization receives emails sent from one of these email addresses, no notification will appear in the relevant rooms in your "My Rooms" list.
		</div>
		</div>
		
		<div style={{margin:'15px 0px'}}>
		Email Sender Notification Exceptions:
		<div style={{marginTop:'5px'}}>
		<InputTextarea 
			style={{height:'2em', width:'100%',}}
			autoResize={true}
			value={this.state.email_sender_notification_exceptions} 
			onChange={e => this.setState({email_sender_notification_exceptions: e.target.value.toLowerCase()})}
			/>
		</div>
		</div>

		<div style={{margin:'15px 0px'}}>
		Email Notification Exception Trigger:
		<div style={{marginTop:'5px'}}>
		<InputTextarea 
			style={{height:'2em', width:'100%',}}
			autoResize={true}
			value={this.state.email_notification_exception_trigger} 
			onChange={e => this.setState({email_notification_exception_trigger: e.target.value.toLowerCase()})}
			/>
		</div>
		</div>

	{this.state.updating && <ProgressSpinner/>}
	</Card>);
	}
}

//------------------ Room Categories  ------------------------

class RoomCategories extends Component {
	constructor() {
		super();
		const categories = ldb.data.org.settings.room_categories || [];
		const rcats = categories.join(' : ');
		this.state = { rcats, updating:false, };
	}
	
	
	update = () => {
		const {rcats} = this.state;

		// if rcats is '', don't return [''], instead return []
		//	else, split them into an array.
		const room_categories = rcats ? rcats.split(':').map(
				cat => cat.trim() ) : [];

		const new_settings = {room_categories};
		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true});

	}

	updated = (error) => {
		this.setState({updating:false});
	}


	render() {
		const is_org_admin = ldb.data.me.is_org_admin;
		
		const footer = is_org_admin ? <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span> : '';

		const subTitle = 'Category names separated by colon (:)';
		
		const is_text_input_disabled = is_org_admin ? false : true;

		const explain_text = is_org_admin ? '' : 'Please contact your organization administrator if you need to update this field';

		return (
	<Card title="Room Categories" subTitle={subTitle} footer={footer}
		className="room-categories"
		>
		<InputTextarea 
			autoResize={true}
			disabled={is_text_input_disabled}
			value={this.state.rcats} 
			onChange={ e => this.setState({rcats: 
						e.target.value }) }
			/>
		{explain_text}
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ AI Feature Settings ----------------------

class AIFeaturesSettings extends Component {
	constructor() {
		super();
		const ai_features_enabled = ldb.data.org.ai_features_enabled || false;

		this.state = { ai_features_enabled, updating : false };
	}
	
	update = () => {
		const {ai_features_enabled} = this.state;
		
		const new_setting = ai_features_enabled ? 'enabled' : 'disabled';
		
		const args = {
			cmd: 'ai_features_setting', 
			new_setting: new_setting,
			tos: false,
		}
		
		api( args, this.updated, this.updated );
		
		this.setState({updating : true});

	}

	updated = (error) => {
		this.setState({updating : false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		return (
	<Card title="AI Features Settings" footer={footer}>
	
	<div style={{margin:'5px 0px'}}>
		Current AI Credits: {ldb.data.org.ai_credits}
	</div>
	
	<br/>
	
	<div style={{margin:'5px 0px'}}>
		AI Features Enabled:
		{em(0.5)}
		<Checkbox onChange={e=> {
				const ai_features_enabled = e.checked;
				this.setState({ai_features_enabled});
			}}
			checked={this.state.ai_features_enabled}
		/>
		
		<div style={{fontSize:'x-small'}}>
		When on, all staff for this firm will be able to use the AI Assist feature when composing new emails or reply emails. 
		</div>
	</div>
			
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ Room Info Fields  ------------------------

const room_info_header= <div className="p-card-title" 
			style={{padding:'0.8em 0 0 0.8em'}} >Custom Room Fields
		{' '}
	</div>;

const room_info_help = () => (
	<HelpInfo header="About Custom Room Fields" className="normal">
	<div>
		<p>
		Here you can define custom fields for each room.
		You can set different values for those fields in each room
		in About tab.
		</p>

		<p>
		Examples: Address, Customer Number, Loan Type
		</p>

		<p>
		Fields can be of these types: 
		<blockquote>
			text, textarea, date, link, dropdown, 
				checklist, staff, allstaff
		</blockquote>
		</p>

		<p>
		Example:
		</p>
		<pre>
			Address = textarea
			<br/>
			Loan Type = dropdown: Fixed : Variable
			<br/>
			CustomerID
			<br/>
			Due = date
			<br/>
			Dinner = checklist: Groceries : Cook : Eat : Cleanup
			<br/>
			Primary = allstaff
			<br/>
			Leader = staff
			<br/>
			TDLink = link
			<br/>
		</pre>
	</div>
	</HelpInfo>
)

function is_choice_kind(kind) {
	return ['dropdown', 'checklist'].indexOf(kind) >= 0;
}

function rif2buf(fields) {
	let buf = '';

	for (const key in fields) {
		const field = fields[key];
		const choices = field.choices || [];
		buf += field.name + ' = ' + field.kind;
		if (is_choice_kind(field.kind))
			buf += ' : ' + choices.join(' : ' );
		buf += '\n';
	}

	return buf;
}

function RoomInfoSettings(props) {
	const name = 'room_info_fields';
	const subTitle="Click help for detailed formatting information"
	const init_items = ldb.data.org.settings[name] || {};
	const [buf, setBuf] = React.useState( rif2buf( init_items ) );
	const [working, setWorking] = React.useState(false);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);
	const [cmd, setCmd] = React.useState('');
	
	const parse = buf => {
		const lines = trimsplit(buf, /[\n;]/);
		const fields = {};
		lines.forEach(line => {
			const parts = trimsplit(line, '=');
			if (parts.length == 0)
				return;
			const name = parts[0];
			if (parts.length == 1) {
				fields[ name ] = {
					name,
					kind: 'text',
				};
				return;
			}
			// eg. State = dropdown : WA, IL, CA, NY
			//     portfolio = link
			//     zip = text

			const details = trimsplit( parts[1], ':' );
			const kind = details[0] || 'text';

			fields[ name ] = {
				name,
				kind,
			};
			if (is_choice_kind(kind))
				fields[name].choices = details.slice(1);
		});
		log('org', 'room_info_fields', fields);
		return fields;
	}
	
	const refresh_data = () => {
		setBuf(rif2buf(ldb.data.org.settings.room_info_fields));
	}

	const update = () => {
		if (working) {
			growl('Operating in progress. Please wait');
			return;
		}

		const new_settings = {};	
		// use name var, not literally. 
		//	so couldn't use new_settings[name] = myparse..
		new_settings[name] = parse(buf); // room_info_fields

		const args = {
			cmd: 'org_settings_update', 
			new_settings,
		}
		api( args, (error, db) => setWorking(false) );
		setWorking(true);
	}

	let footer = '', disabled = true;
	if (ldb.data.me.is_org_admin) {
		footer = <span>
			<Button label="Update" onClick={update} 
				className="p-button-outlined p-button-raised" />
		</span> ;
		disabled = false;
	}
		
		const clients_header = <Toolbar
			right={
	<div className="p-toolbar-group-right">
		{room_info_help()}
		{' '}
		<Button label="Rename Field"
			className="p-button-raised p-button-outlined p-button-raised"
			icon='pi pi-fw pi-clone'
			tooltip="Rename Field"
			onClick={() => setCmd('rename')}
		/>
		<Button label="Delete Field"
			className="p-button-raised p-button-outlined p-button-raised"
			icon='pi pi-times'
			tooltip="Delete Field"
			onClick={() => setCmd('delete')}
		/>
	</div>
			}
			/>
		
		return (
	<Card title="Custom Room Fields" header={clients_header}
		subTitle={subTitle} footer={footer} 
			className="org-setting" >
		<InputTextarea 
			ref={stretchHeight}
			autoResize={true}
			disabled={disabled}
			value={buf}
			onChange={ e => setBuf(e.target.value) }
				/>
	{working && <ProgressSpinner/>}
	{cmd == 'rename' && <RenameField reloadfun={refresh_data} setCmd={setCmd} />}
	{cmd == 'delete' && <DeleteField reloadfun={refresh_data} setCmd={setCmd} />}

	</Card> );
}

class ClearTagNotSets extends Component {
	constructor() {
		super();
		
		this.state = {updating:false};
	}

	help_popup = () => {
		return (<HelpInfo header="Clear Tag Not Set Markings" className="normal">
		<div>
		<p>
			This will clear all the existing "Tag Not Set" warnings for all shared emails in all rooms for your organization.
		</p>
		<p>
			You can use this when you feel that all existing shared emails have already been addressed (for example, when you have created new rooms and many old emails have been tranferred into the shared emails for those rooms). New shared emails transerred after you use this feature can still have the "Tag Not Set" indicator, if it applies to them.
		</p>
		</div>
		</HelpInfo>)
	}
		
	update = () => {
		const args = {
			cmd: 'org_clear_tag_not_set_flags', 
		}
		api( args, this.updated );

		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});

		growl('Cleared Tag Not Set Markings', 'All untagged email markings have been cleared');
	}

	render() {
		const footer = <span><Button label="Clear Tag Not Set Markings" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		return (
	<Card title="Clear Tag Not Set Markings">
	<span style={{fontSize:'small'}}><b>This will clear all the existing "Tag Not Set" warnings for all shared emails in all rooms for your organization.</b></span>
	{em(0.5)}
	{this.help_popup()}
	<br/><br/>
	{this.state.updating && <ProgressSpinner/>}
	{(!this.state.updating) && footer}
	</Card> );
	}
}

class RenameField extends Focusable {
	constructor(props) {
		super(props);
		
		const field_name = '';
		const new_field_name = '';
		const fields = ldb.data.org.settings.room_info_fields;
		const options = Object.keys(fields).map( field => ({label:field, value:field}) );
		options.unshift({label:'------------', value:''});

		const working = false;
		const is_visible = true;

		this.state = {working, options, field_name, new_field_name, is_visible};

		window.g_renamingField = this;
	}
	
	close = () => {
		this.props.setCmd('');
		this.setState({working: false, field_name: '', new_field_name: ''});
	}

	has_errors = () => {
		if (this.state.field_name === '') {
			growl('Error', 
		'Please select a field to rename', 'error');
			return true;
		}

		if (this.state.new_field_name.trim() === '') {
			growl('Error', 
		'Please enter a new name for the field', 'error');
			return true;
		}
		
		return false;
	}

	rename = () => {
		if (this.has_errors())
			return;

		const {field_name, new_field_name} = this.state;
		
		const op = 'rename_room_field';

		const args = {
			cmd: 'mod_org', 
			op, field_name, new_field_name,
		}
		api( args, this.renamed );
		
		this.setState({working: true});
	}

	set_options = () => {
		const fields = ldb.data.org.settings.room_info_fields;
		const options = Object.keys(fields).map( field => ({label:field, value:field}) );
		options.unshift({label:'------------', value:''});
		this.setState({options: options});
	}

	renamed = (error) => {
		if (!error) {
			growl('Renamed field');
		}
		this.close();
		
		this.set_options();
		this.props.reloadfun();
	}
	
	render() {
	 	return (
		<Dialog header="Rename Field"
		    onHide={e => this.close()}
		    className="modal-35-command"
		    visible={this.state.is_visible}
		    draggable={true}
		    modal={true}
		    maximizable={true}
		>
	
	<div className="p-grid">
		<div className="p-col-3">Choose a Field:</div>
		<div className="p-col-9">
			<Dropdown value={this.state.field_name}
				className="toolbar-dropdown mailbox-options"
	    			options={this.state.options}
				onChange={e => this.setState({field_name: e.value, new_field_name: e.value})}
			/>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-3">New Field Name:</div>
		<div className="p-col-9">
			<InputText
				value={this.state.new_field_name} 
				placeholder=""
				onChange={e => this.setState({new_field_name: e.target.value})}
			/>
		</div>
	</div>
	{!this.state.working && <div className="p-grid">
		<div className="p-col-3">
		</div>
		<div className="p-col-9">
			<br/>
			<Button label="Rename Field"
				icon="pi pi-directions"
				onClick={this.rename}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</Dialog>
		)
	}
}

class DeleteField extends Focusable {
	constructor(props) {
		super(props);
		
		const field_name = '';
		const fields = ldb.data.org.settings.room_info_fields;
		const options = Object.keys(fields).map( field => ({label:field, value:field}) );
		options.unshift({label:'------------', value:''});

		const working = false;
		const is_visible = true;
			
		this.state = {working, options, field_name, is_visible};
	
		window.g_renamingField = this;
	}
	
	close = () => {
		this.props.setCmd('');
		this.setState({working: false, field_name: ''});
	}
	
	has_errors = () => {
		if (this.state.field_name === '') {
			growl('Error', 
		'Please select a field to delete', 'error');
			return true;
		}

		return false;
	}
	
	delete = () => {
		if (this.has_errors())
			return;

		const {field_name} = this.state;
		
		const op = 'delete_room_field';

		const args = {
			cmd: 'mod_org', 
			op, field_name,
		}
		api( args, this.deleted );
		
		this.setState({working: true});
	}

	set_options = () => {
		const fields = ldb.data.org.settings.room_info_fields;
		const options = Object.keys(fields).map( field => ({label:field, value:field}) );
		options.unshift({label:'------------', value:''});
		this.setState({options: options});
	}

	deleted = (error) => {
		if (!error) {
			growl('Deleted field');
		}
		this.close();
				
		this.set_options();
		this.props.reloadfun();
	}
	
	render() {
	 	return (
		<Dialog header="Delete Field"
		    onHide={e => this.close()}
		    className="modal-35-command"
		    visible={this.state.is_visible}
		    draggable={true}
		    modal={true}
		    maximizable={true}
		>

	<div className="p-grid">
		<div className="p-col-3">Choose a Field:</div>
		<div className="p-col-9">
			<Dropdown value={this.state.field_name}
				className="toolbar-dropdown mailbox-options"
	    			options={this.state.options}
				onChange={e => this.setState({field_name: e.value})}
			/>
		</div>
	</div>
	{!this.state.working && <div className="p-grid">
		<div className="p-col-3">
		</div>
		<div className="p-col-9">
			<br/>
			<Button label="Delete Field"
				icon="pi pi-directions"
				onClick={this.delete}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</Dialog>
		)
	}
}

//----- Bulk Manage Operations

function get_all_rooms(setRooms, setLoaded, callback) {
	//let room_options = [];
	//
	//ldb.data.rooms._idlist.forEach(function(rid) {
	//	let room = get_room(rid);
	//	
	//	if (room.archive_of) {
	//		return;
	//	}
	//
	//	if (room.is_mailbox) {
	//		return;
	//	}
	//	
	//	room_options.push(room);		
	//});
	
	//setRooms(room_options);
	//setLoaded(true);
	//if (callback)
	//	callback(room_options);
	
	const args = { cmd: 'mod_org', op: 'get_rooms', };
	
	api( args, (error, db) => {
		const rooms = db.result;
		rooms.sort(sort_room_by_category);

		setRooms(rooms);
		setLoaded(true);
		if (callback)
			callback(rooms);
	});
	
	return (
	<div>
		<h3>Bulk Rooms</h3>
		<ProgressSpinner />
	</div>
	);
}

function get_staff_options(help=true, room=null) {
	const source = room ? room.staffs : ldb.data.staffs;
	const soptions = map_list(source, staff => (
		{label: staff.name + (staff.is_org_admin ? ' (admin)': ''),
		value: staff.id}
	));
	if (help)
		soptions.unshift({label:'Please Choose Staff', value:0});

	return soptions;
}

function StaffDropdown(props) {
	const {sid} = props;

	return (
	<div className="p-col-3">
		<Dropdown value={sid}
			options={ get_staff_options() }
			onChange={ e => props.onChange(e.value) }
		/>
	</div>
	);

}

function staff_rids(rooms, sid) {
	const rids = [];
	rooms.forEach(room => {
		if (list_has_ele(room.sids, sid))
			rids.push(room.id);
	});
	return rids;
}

function ShowClients(props) {
	const room = props.room;
	const name_emails = client => {
		const emails = client.emails.map((email) => email);
		return client.name + ': ' + emails.join(', ');
	};
	
	console.log('ROOM', room);

	return <React.Fragment>
	{room.clients.map((client) => (
		<span key={client.id} title={name_emails(client)} className="rcname">
			{get_first_name(client.name)}
		</span> ))}
	</React.Fragment>;
}

function BulkAssignRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [rooms, setRooms] = React.useState({});
	const [sid, setSid] = React.useState(0);
	const [chosen, setChosen] = React.useState([]);
	const [inrooms, setInrooms] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);

	if (!loaded) 
		return get_all_rooms(setRooms, setLoaded);
	
	const cmdon = chosen.length ? '' : ' invisible';
	log('org', 'BulkAssignRooms', {sid, rooms, chosen, inrooms});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Bulk Assign Rooms 
		</div>

		<StaffDropdown sid={sid} 
			onChange={nsid => {
				setSid(nsid);
				setChosen([]);
				setInrooms( staff_rids(rooms, nsid) );
				reload();
			}} />
			
		<div className="p-col-1">
		</div>

		<div className={"p-col-3" + cmdon}>
		    <Button label="Assign"
			icon="pi pi-fw pi-check"
			onClick={e => {
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = { cmd: 'mod_org', op: 'assign_rooms', 
			target_sid:sid, rids: chosen, bid: bid };
		api( args, (error, db) => {
			// setDone(true);
		});
		setLoaded(false);

		window.g_progCircle.add(bid, 
			"Assign " + get_staff(sid).name + " to Rooms",
				chosen.length);
		
				
			}}
			/>
		</div>

		</h3>

	<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-4">
			Room Name
		</div>
		<div className="p-col-3">
			Category
		</div>
		<div className="p-col-2">
			Staff
		</div>
		<div className="p-col-2">
			Clients
		</div>
	</div>
		<Dialog header="Bulk Assign Rooms" visible={done}
			onHide={e => setDone(false)}>
			<h3>Please Wait</h3>
			<b>Work In Progress. Add is NOT Implemented yet</b>
			These operations can take several minutes.
			<br/>
			You will receive an email when the operations are complete.
			<br/>
			Please wait until you receive that email before you assign this staff member to additional rooms.
			<br/>
			You can however assign other staff member to more rooms.
		</Dialog>
		{rooms.map((room, i) => {
		const nocheck = sid ? '' : ' invisible';
		
		let lroom = get_room(room.id);
		
		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className={"p-col-1 " + nocheck}>
			<Checkbox onChange={e=> {
					if (e.checked) {
						chosen.push(room.id);
						inrooms.push(room.id);
					}
					else {
						list_rm_ele(chosen, room.id);
						list_rm_ele(inrooms, room.id);
					}
					setChosen(chosen);
					setInrooms(inrooms);
					reload();
					log('org', 'chosen', {chosen, inrooms});
				}}
				checked={list_has_ele(inrooms, room.id)}
				disabled={list_has_ele(room.sids, sid)}
			/>
		</div>
		<div className="p-col-4">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{lroom && is_room_a_cimr_room(lroom) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-3">
			{room.category}
		</div>
		<div className="p-col-2">
			{room.sids.map( (sid, j) => {
				const sname = get_staff(sid).name;
				return (
				<span key={j} title={sname} className="rsname">
					{get_first_name(sname)}
				</span> )})}
		</div>
		<div className="p-col-2">
			<ShowClients room={room} />
		</div>
	</div>
		)})}
	</div>
	);
}

function BulkUnassignRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [rooms, setRooms] = React.useState({});
	const [sid, setSid] = React.useState(0);
	const [chosen, setChosen] = React.useState([]);
	const [inrooms, setInrooms] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);

	if (!loaded) 
		return get_all_rooms(setRooms, setLoaded, rooms => {
			if (sid) {
				setChosen([]);
				setInrooms( staff_rids(rooms, sid) );
				reload();
			}
		});

	const cmdon = chosen.length ? '' : ' invisible';

	log('org', 'BulkUnAssignRooms', {sid, rooms, chosen, inrooms});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Remove from Rooms
		</div>

		<StaffDropdown sid={sid} 
			onChange={nsid => {
				setSid(nsid);
				setChosen([]);
				setInrooms( staff_rids(rooms, nsid) );
				reload();
			}} />
			
		<div className="p-col-1">
		</div>

		<div className={"p-col-3" + cmdon}>
		    <Button label="Unlink"
			icon="pi pi-fw pi-times"
			onClick={e => {
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = { cmd: 'mod_org', op: 'unassign_rooms', 
			target_sid:sid, rids: chosen, bid: bid};
		api( args, (error, db) => {
			// setDone(true);
		});
		setLoaded(false);

		window.g_progCircle.add(bid, 
			"Unassigned " + get_staff(sid).name + " from Rooms",
				chosen.length);
				
			}}
			/>
		</div>

		</h3>
	<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
			Room Name
		</div>
		<div className="p-col-3">
			Category
		</div>
		<div className="p-col-3">
			Staff
		</div>
	</div>
		{rooms.map((room, i) => {
			if (!list_has_ele(inrooms, room.id))
				return null;

		const nocheck = sid ? '' : ' invisible';
		
		let lroom = get_room(room.id);

		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className={"p-col-1 " + nocheck}>
			<Checkbox onChange={e=> {
					if (e.checked) {
						chosen.push(room.id);
					}
					else {
						list_rm_ele(chosen, room.id);
					}
					setChosen(chosen);
					reload();
					log('org', 'chosen', {chosen, inrooms});
				}}
				checked={list_has_ele(chosen, room.id)}
			/>
		</div>
		<div className="p-col-5">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{lroom && is_room_a_cimr_room(lroom) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-3">
			{room.category}
		</div>
		<div className="p-col-3">
			{room.sids.map( (sid, j) => {
				const sname = get_staff(sid).name;
				return (
				<span key={j} title={sname} className="rsname">
					{get_first_name(sname)}
				</span> )})}
		</div>
	</div>
		)})}
	</div>
	);
}

//----- Auto Share

function BulkSetAutoShareRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [rooms, setRooms] = React.useState({});
	const [chosen, setChosen] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	if (!loaded) 
		return get_all_rooms(setRooms, setLoaded, rooms => {
			let amrooms = [];
			rooms.forEach(function(r) {
				if (r.auto_move)
					amrooms.push(r.id);
			});
			
			setChosen(amrooms);
			reload();
		});

	log('org', 'BulkSetAutoShareRooms', {rooms, chosen});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Bulk Set Auto Share Rooms
		</div>

		<div className="p-col-1">
		</div>

		<div className="p-col-3">
		    <Button label="Update Auto Share Settings"
			onClick={e => {
		const args = { cmd: 'mod_org', op: 'update_auto_move', 
			rids: chosen};
		api( args, (error, db) => {
			// setDone(true);
			growl('Updated org auto share settings');
		});
		setLoaded(false);
			}}
			/>
		</div>

		</h3>
		
		<div className="p-grid">
		
		<div className="p-col-12">
			<Button label="Check All"
				size="small"
				onClick={e => {
					let amrooms = [];
					rooms.forEach(function(r) {
						amrooms.push(r.id);
					});
					
					setChosen(amrooms);
					reload();
				}}
			/>
			{em(1)}
			<Button label="Uncheck All"
				size="small"
				onClick={e => {
					let amrooms = [];
					setChosen(amrooms);
					reload();
				}}
			/>
		</div>
		
		</div>
	<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
			Room Name
		</div>
		<div className="p-col-5">
			Category
		</div>
	</div>
		{rooms.map((room, i) => {
		let lroom = get_room(room.id);
		
		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className="p-col-1">
			<Checkbox onChange={e=> {
					if (e.checked) {
						chosen.push(room.id);
					}
					else {
						list_rm_ele(chosen, room.id);
					}
					setChosen(chosen);
					reload();
					log('org', 'chosen', {chosen});
				}}
				checked={list_has_ele(chosen, room.id)}
			/>
		</div>
		<div className="p-col-5">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{lroom && is_room_a_cimr_room(lroom) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-5">
			{lroom && <span>{lroom.category}</span>}
		</div>
	</div>
		)})}
	</div>
	);
}

//----- Category

function BulkSetCategoryRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [working, setWorking] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [category, setCategory] = React.useState('');
	const [rooms, setRooms] = React.useState([]);
	const [chosen, setChosen] = React.useState([]);
	const [unchosen, setUnchosen] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);
	
	const options = room_category_options();
	
	if (!loaded) {
		let room_options = [];
		let chosen_rooms = [];
		let unchosen_rooms = [];
		
		ldb.data.rooms._idlist.forEach(function(rid) {
			let room = get_room(rid);
			
			if (room.archive_of) {
				return;
			}
			
			room_options.push(room);
			
			if (room.category == category) {
				chosen_rooms.push(room.id);
			} else {
				unchosen_rooms.push(room.id);
			}
		});
		
		room_options.sort(sort_room_by_category);
		
		setRooms(room_options);
		setChosen(chosen_rooms);
		setUnchosen(unchosen_rooms);
		setLoaded(true);
		reload();
	}

	log('org', 'BulkSetCategoryRooms', {rooms, chosen});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Bulk Set Room Categories
		</div>

		<div className="p-col-2">
			<Dropdown value={category}
				placeholder="Choose"
				options={options}
				onChange={e => {setCategory(e.value);setLoaded(false);reload();}} 
			/>
		</div>

		<div className="p-col-3">
		    <Button label="Update Room Categories"
			onClick={e => {
				let add_list = [];
				let remove_list = [];
				
				chosen.forEach(function(rid) {
					let room = get_room(rid);
					if (room.category != category) {
						add_list.push(rid);
					}
				});

				unchosen.forEach(function(rid) {
					let room = get_room(rid);
					if (room.category == category) {
						remove_list.push(rid);
					}
				});

				const args = { cmd: 'staff_update_room_settings', op: 'update_categories', 
					category: category, add_rids: add_list, remove_rids: remove_list};
				
				setWorking(true);
				
				api( args, (error, db) => {
					growl('Updated room categories');
					setWorking(false);
					reload();
				});
			}}
			/>
		</div>

		</h3>
	
	{working && <ProgressSpinner/>}
	{(!working) && <div>

	{(!category) && <div>
		Select a category from the dropdown above
	</div>}

	{category && <div>
		<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
			Room Name
		</div>
		<div className="p-col-5">
			Current Category
		</div>
	</div>
		{rooms.map((room, i) => {
		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className="p-col-1">
			<Checkbox onChange={e=> {
					if (e.checked) {
						list_rm_ele(unchosen, room.id);
						chosen.push(room.id);
					} else {
						list_rm_ele(chosen, room.id);
						unchosen.push(room.id);
					}
					setChosen(chosen);
					setUnchosen(unchosen);
					reload();
					log('org', 'chosen', {chosen}, 'unchosen', {unchosen});
				}}
				checked={list_has_ele(chosen, room.id)}
			/>
		</div>
		<div className="p-col-5">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{is_room_a_cimr_room(room) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-5">
			{room.category} 
		</div>
	</div>
		)})}
	</div>}

	</div>}
	
	</div>
	);
}

//----- Favorite

function BulkSetFavoriteRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [working, setWorking] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [rooms, setRooms] = React.useState([]);
	const [chosen, setChosen] = React.useState([]);
	const [unchosen, setUnchosen] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	if (!loaded) {
		let room_options = [];
		let chosen_rooms = [];
		let unchosen_rooms = [];
		
		ldb.data.rooms._idlist.forEach(function(rid) {
			let room = get_room(rid);
			
			if (room.archive_of) {
				return;
			}
			
			room_options.push(room);
			
			if (is_favorite_room(room)) {
				chosen_rooms.push(room.id);
			} else {
				unchosen_rooms.push(room.id);
			}
		});
		
		room_options.sort(sort_room_by_category);
		
		setRooms(room_options);
		setChosen(chosen_rooms);
		setUnchosen(unchosen_rooms);
		setLoaded(true);
		reload();
	}

	log('org', 'BulkSetFavoriteRooms', {rooms, chosen});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Bulk Set Favorite Rooms
		</div>

		<div className="p-col-1">
		</div>

		<div className="p-col-3">
		    <Button label="Update Favorite Room Settings"
			onClick={e => {
				let add_list = [];
				let remove_list = [];
				
				chosen.forEach(function(rid) {
					let room = get_room(rid);
					if (!is_favorite_room(room)) {
						add_list.push(rid);
					}
				});

				unchosen.forEach(function(rid) {
					let room = get_room(rid);
					if (is_favorite_room(room)) {
						remove_list.push(rid);
					}
				});
				
				const args = { cmd: 'staff_update_room_settings', op: 'update_favorites', 
					add_rids: add_list, remove_rids: remove_list};
				
				setWorking(true);
				
				api( args, (error, db) => {
					growl('Updated favorite room settings');
					setWorking(false);
					reload();
				});
			}}
			/>
		</div>

		</h3>
	
	{working && <ProgressSpinner/>}
	{(!working) && <div>
	<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
			Room Name
		</div>
		<div className="p-col-5">
			Category
		</div>
	</div>
		{rooms.map((room, i) => {
		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className="p-col-1">
			<Checkbox onChange={e=> {
					if (e.checked) {
						list_rm_ele(unchosen, room.id);
						chosen.push(room.id);
					} else {
						list_rm_ele(chosen, room.id);
						unchosen.push(room.id);
					}
					setChosen(chosen);
					setUnchosen(unchosen);
					reload();
					log('org', 'chosen', {chosen}, 'unchosen', {unchosen});
				}}
				checked={list_has_ele(chosen, room.id)}
			/>
		</div>
		<div className="p-col-5">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{is_room_a_cimr_room(room) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-5">
			{room.category}
		</div>
	</div>
		)})}
	</div>}
	
	</div>
	);
}

//----- Bookmark

function BulkSetBookmarkRooms(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [working, setWorking] = React.useState(false);
	const [done, setDone] = React.useState(false);
	const [rooms, setRooms] = React.useState([]);
	const [chosen, setChosen] = React.useState([]);
	const [unchosen, setUnchosen] = React.useState([]);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	if (!loaded) {
		let room_options = [];
		let chosen_rooms = [];
		let unchosen_rooms = [];
		
		ldb.data.rooms._idlist.forEach(function(rid) {
			let room = get_room(rid);
			
			if (room.archive_of) {
				return;
			}
			
			room_options.push(room);
			
			if (is_return_later_room(room)) {
				chosen_rooms.push(room.id);
			} else {
				unchosen_rooms.push(room.id);
			}
		});
		
		room_options.sort(sort_room_by_category);
		
		setRooms(room_options);
		setChosen(chosen_rooms);
		setUnchosen(unchosen_rooms);
		setLoaded(true);
		reload();
	}

	log('org', 'BulkSetBookmarkRooms', {rooms, chosen});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			Bulk Set Bookmark Rooms
		</div>

		<div className="p-col-1">
		</div>

		<div className="p-col-3">
		    <Button label="Update Bookmarked Room Settings"
			onClick={e => {
				let add_list = [];
				let remove_list = [];
				
				chosen.forEach(function(rid) {
					let room = get_room(rid);
					if (!is_return_later_room(room)) {
						add_list.push(rid);
					}
				});

				unchosen.forEach(function(rid) {
					let room = get_room(rid);
					if (is_return_later_room(room)) {
						remove_list.push(rid);
					}
				});
				
				const args = { cmd: 'staff_update_room_settings', op: 'update_bookmarks', 
					add_rids: chosen, remove_rids: unchosen};
				
				setWorking(true);
				
				api( args, (error, db) => {
					growl('Updated bookmarked room settings');
					setWorking(false);
					reload();
				});
			}}
			/>
		</div>

		</h3>
	
	{working && <ProgressSpinner/>}
	{(!working) && <div>
	<div className="p-grid legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
			Room Name
		</div>
		<div className="p-col-5">
			Category
		</div>
	</div>
		{rooms.map((room, i) => {
		let bgcolor = (i % 2 == 0) ? '#fff' : '#edf0f5';
		
		return (
	<div key={i} className="p-grid" style={{background: bgcolor}}>
		<div className="p-col-1">
			<Checkbox onChange={e=> {
					if (e.checked) {
						list_rm_ele(unchosen, room.id);
						chosen.push(room.id);
					} else {
						list_rm_ele(chosen, room.id);
						unchosen.push(room.id);
					}
					setChosen(chosen);
					setUnchosen(unchosen);
					reload();
					log('org', 'chosen', {chosen}, 'unchosen', {unchosen});
				}}
				checked={list_has_ele(chosen, room.id)}
			/>
		</div>
		<div className="p-col-5">
			<Link to={go_url('room', room.id)}>
				{room.name} 
			</Link>
			{em(0.5)}
			{is_room_a_cimr_room(room) && <i className="pi pi-fw pi-link"
				title="Clients are in multiple rooms">
			</i>}
		</div>
		<div className="p-col-5">
			{room.category}
		</div>
	</div>
		)})}
	</div>}
	
	</div>
	);
}

//----- Org Tags

function OrgTags(props) {
	const [tags, setTags] = React.useState({});
	const [sid, setSid] = React.useState(0);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);

	const getTags = nsid => {
		setSid(nsid);
		if (nsid && tags[nsid] === undefined) {
			const args = { cmd: 'mod_org', 
					target_sid: nsid, op: 'get_tags', };

			api( args, (error, db) => {
				tags[nsid] = db.result;
				setTags(tags);
				reload();
			});
		}
	};

	const tids = tags[sid] === undefined ? [] : 
			Object.keys(tags[sid]._items);

	log('org', 'BulkTags', {sid, tags, tids});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			View Tags
		</div>

		<StaffDropdown sid={sid} onChange={getTags} />
			
		<div className="p-col-4">
		</div>

		</h3>

		<div className="p-grid legend">
			<div className="p-col-1">
				ID
			</div>
			<div className="p-col-4">
				Note
			</div>
			<div className="p-col-2" >
				Added
			</div>
			<div className="p-col-1" >
				To
			</div>
			<div className="p-col-1">
				By
			</div>
			<div className="p-col-3">
				Room
			</div>
		</div>

	{tids.map( (tid, i) => {
		
	const tag = tags[sid]._items[tid];
	const by_staff = get_staff(tag.by_sid);
	const by = by_staff ? by_staff.name : '';
	const to = tag.to_sid ? get_staff(tag.to_sid).name : '';
	const room = get_room(tag.rid);
	const rname = room ? room.name : '';

	return (
<div className="p-grid mytags-row" key={i} >
	<div className="p-col-1">
		{tag.id}
	</div>
	<div className="p-col-4">
		{tag.note}
	</div>
	<div className="p-col-2" >
		{edate(tag.dt_added)}
	</div>
	<div className="p-col-1" title={to}>
		{get_first_name(to)}
	</div>
	<div className="p-col-1" title={by}>
		{get_first_name(by)}
	</div>
	<div className="p-col-3">
		{rname}
	</div>
</div>
	);
})}
	</div>
	);
}

//----- Org Tasks

function OrgTasks(props) {
	const [tasks, setTasks] = React.useState({});
	const [sid, setSid] = React.useState(0);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);

	const getTasks = nsid => {
		setSid(nsid);
		if (nsid && tasks[nsid] === undefined) {
			const args = { cmd: 'mod_org', 
					target_sid: nsid, op: 'get_tasks', };

			api( args, (error, db) => {
				tasks[nsid] = db.result;
				setTasks(tasks);
				reload();
			});
		}
	};

	const tids = tasks[sid] === undefined ? [] : 
			Object.keys(tasks[sid]._items);

	log('org', 'BulkTasks', {sid, tasks, tids});

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			<Link to="/settings/org" >
				&lt; Manage
			</Link>
		</div>

		<div className="p-col-3">
			View Tasks
		</div>

		<StaffDropdown sid={sid} onChange={getTasks} />
			
		<div className="p-col-4">
		</div>

		</h3>

		<div className="p-grid legend">
			<div className="p-col-1">
				ID
			</div>
			<div className="p-col-4">
				Note
			</div>
			<div className="p-col-2" >
				Added
			</div>
			<div className="p-col-1" >
				To
			</div>
			<div className="p-col-1">
				By
			</div>
			<div className="p-col-3">
				Room
			</div>
		</div>

	{tids.map( (tid, i) => {
		
	const task = tasks[sid]._items[tid];
	const by_staff = get_staff(task.by_sid);
	const by = by_staff ? by_staff.name : '';
	const to = task.to_sid ? get_staff(task.to_sid).name : '';
	const room = get_room(task.rid);
	const rname = room ? room.name : '';

	return (
<div className="p-grid mytags-row" key={i} >
	<div className="p-col-1">
		{task.id}
	</div>
	<div className="p-col-4">
		{task.name}
	</div>
	<div className="p-col-2" >
		{edate(task.dt_added)}
	</div>
	<div className="p-col-1" title={to}>
		{get_first_name(to)}
	</div>
	<div className="p-col-1" title={by}>
		{get_first_name(by)}
	</div>
	<div className="p-col-3">
		{rname}
	</div>
	<div className="p-col-12">
		<MyTaskDetail task={task} />
	</div>
</div>
	);
})}
	</div>
	);
}

//----- Bulk Manage Links
function AdminLinks(props) {
	return (
	<Card title="Manage" 
		subTitle="Org Admin Links" 
		className="admin-links"
		footer="You are an administrator.">
	<ul>

	<li>
		<Link to="/settings/org/BulkAssignRooms">
			Assign Staff to Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkUnassignRooms">
			Remove Staff from Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetAutoShareRooms">
			Set Auto Share for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetCategoryRooms">
			Set Category for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetFavoriteRooms">
			Set Favorite for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetBookmarkRooms">
			Set Bookmark for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/OrgTags">
			View Tags
		</Link>
	</li>

	<li>
		<Link to="/settings/org/OrgTasks">
			View Tasks
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkCreateRooms">
			Bulk Create Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkEmailLists">
			Bulk Email Lists
		</Link>
	</li>
	
	<li>
		<Link to="/settings/org/BulkArchive">
			Bulk Archive Emails
		</Link>
	</li>

	</ul>
	</Card> 
	);
}

//----- Non-Admin Bulk Manage Links
function NonAdminLinks(props) {
	return (
	<Card title="Manage" 
		subTitle="Org Links" 
		className="admin-links">
	<ul>

	<li>
		<Link to="/settings/org/BulkSetCategoryRooms">
			Set Category for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetFavoriteRooms">
			Set Favorite for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkSetBookmarkRooms">
			Set Bookmark for Multiple Rooms
		</Link>
	</li>

	<li>
		<Link to="/settings/org/BulkArchive">
			Bulk Archive Emails
		</Link>
	</li>

	</ul>
	</Card> 
	);
}

const op_name2comp = { BulkAssignRooms, BulkUnassignRooms,
	BulkSetCategoryRooms, BulkSetAutoShareRooms, BulkSetFavoriteRooms,
	BulkSetBookmarkRooms, OrgTags, OrgTasks, BulkCreateRooms,
	BulkEmailLists, BulkArchive};

function OrgProfile(props) {
	const {command} = props.match.params;
	if (command) {
		const TagName = op_name2comp[command];
		return <TagName />;
	}

	return (
<div className='profile'>
<div className="p-grid">
<div className="p-col-12">
	<Card title={ldb.data.org.name + ' - Settings'}
			className="profile-settings-card">
		<div className="p-grid">
			<div className="p-col-8">
				<TaskCategories />
			</div>
			<div className="p-col-4">
				<TaskDueDate />
			</div>
			<div className="p-col-8">
				<RoomCategories />
			</div>
			{ldb.data.me.is_org_admin && 
			<div className="p-col-4">
				<AdminLinks />
			</div>
			}
			{(!ldb.data.me.is_org_admin) && 
			<div className="p-col-4">
				<NonAdminLinks />
			</div>
			}
		</div>
		<div className="p-grid">
			<div className="p-col-12">
				<SMSPresets command={command} />
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-12">
				<TagPresets command={command} />
			</div>
		</div>
		{ldb.data.me.is_org_admin && <div className="p-grid">
			<div className="p-col-12">
				<IpWhitelist command={command} />
			</div>
		</div>}
		{ldb.data.me.is_org_admin && <div className="p-grid">
			<div className="p-col-12">
				<OrgSettings command={command} />
			</div>
		</div>}
		{ldb.data.me.is_org_admin && ldb.data.org.ai_features_accepted_tos && <div className="p-grid">
			<div className="p-col-12">
				<AIFeaturesSettings command={command} />
			</div>
		</div>}
		{false && ldb.data.me.is_org_admin && <div className="p-grid">
			<div className="p-col-12">
				<ClearTagNotSets />
			</div>
		</div>}
		{ldb.data.me.is_org_admin && <div className="p-grid">
			<div className="p-col-12">
				<ClearOrgEmailDrafts />
			</div>
		</div>}
		<div className="p-grid">
			<div className="p-col-12">
				<RoomInfoSettings />
			</div>
		</div>
	</Card>
</div>
</div>
</div>
	)
}

export {OrgProfile, get_staff_options};
